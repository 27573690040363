import { Widgets } from "./Widgets";

export class BackToTop extends Widgets {
    button: HTMLButtonElement;
    init() {
        this.button = this.element.querySelector("button");
        this.button.classList.add('showme')
        this.button.addEventListener('click', e=>{
            this.button.blur();
            this.scrollToTop();
        });
    }
    scrollToTop() {
        this.parent.element.scrollIntoView();
    }
}