import { eHelp, EHELP_EVENTS } from "../eHelp";
import { Widgets } from "../widgets/Widgets";

export class Pages {
    parent: eHelp;
    template: Function;
    element: HTMLElement;
    name: String;
    constructor() {
        const element = document.createElement("div");
        element.classList.add("ehelp-page");
        this.element = element;
    }
    init(...args:string[]){
        console.warn("The init() method is empty on",  this, args);
    }
    async render(html:string, callback:Function | null = null) {
        this.element.innerHTML = html;
        this.parent.loading.stop();
        this.parent.element.replaceChildren(this.element);
        if (callback) {
            callback();
            //deeplink
            document.querySelectorAll("a").forEach(item => {
                if (item.href.toString().match(/deeplink/i)) {
                    item.dataset.navigateTo = `${item.dataset.manufacturerpath}/${item.dataset.devicepath}/${item.dataset.categorypath}/${item.dataset.topicpath}`
                    item.href = "/" + item.dataset.navigateTo;
                    // console.log(item);
                }
            });
        }
        // window.scrollTo(0, 0);
        this.element.scrollIntoView();
        let e = new CustomEvent(EHELP_EVENTS.EHELP_PAGE_RENDERED, {
            bubbles: true,
        });
        this.parent.dataLayer({event: "pageload"});
        this.element.dispatchEvent(e);
    }
    call(url: string) {
        return this.parent.call(url)
    }
    registerWidget(widget: Widgets, name: string) {
        return this.parent.registerWidget(widget, name);
    }
    params() {
        return this.parent.currentLocation().split("/");
    }
    param(n:number) {
        return this.params()[n];
    }
    translate(path_str: string) {
        return this.parent.translate(path_str);
    }
    unload() {

    }
}