var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"widget prs full-width\">\r\n  <div class=\"isituseful\">\r\n    <p class=\"title\">"
    + alias3((lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"widgets/prs/isituseful/title",{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":3,"column":21},"end":{"line":3,"column":65}}}))
    + "</p>\r\n    <p class=\"thanks\">"
    + alias3((lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"widgets/prs/isituseful/thankyou",{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":4,"column":22},"end":{"line":4,"column":69}}}))
    + "</p>\r\n    <button type=\"button\" class=\"btn btn-primary yes\">"
    + alias3((lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"widgets/prs/isituseful/yes",{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":5,"column":54},"end":{"line":7,"column":8}}}))
    + "</button>\r\n    <button type=\"button\" class=\"btn btn-primary no\">"
    + alias3((lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"widgets/prs/isituseful/no",{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":8,"column":53},"end":{"line":10,"column":8}}}))
    + "</button>\r\n  </div>\r\n  <div class=\"evaluation hide\">\r\n    <span class=\"close-btn\">"
    + alias3((lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"widgets/prs/evaluation/close-btn",{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":13,"column":28},"end":{"line":15,"column":8}}}))
    + "</span>\r\n    <p>"
    + alias3((lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"widgets/prs/evaluation/title",{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":16,"column":7},"end":{"line":16,"column":51}}}))
    + "</p>\r\n  <div class=\"horizontal-decorator\"></div>\r\n\r\n    <div class=\"form-floating\">\r\n      <textarea\r\n        class=\"form-control\"\r\n        placeholder=\""
    + alias3((lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"widgets/prs/evaluation/placeholder",{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":22,"column":21},"end":{"line":22,"column":71}}}))
    + "\"\r\n        id=\"user-comment\"\r\n      ></textarea>\r\n      <label for=\"user-comment\">"
    + alias3((lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"widgets/prs/evaluation/placeholder",{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":25,"column":32},"end":{"line":25,"column":82}}}))
    + "</label>\r\n      <button type=\"button\" class=\"btn btn-primary btn-send\">"
    + alias3((lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"widgets/prs/evaluation/send-btn",{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":26,"column":61},"end":{"line":26,"column":108}}}))
    + "</button>\r\n    </div>\r\n  </div>\r\n</div>";
},"useData":true});