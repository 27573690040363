import { EHELP_EVENTS } from "../../../eHelp";
import { Widgets } from "../../../widgets/Widgets";

export class SimpleHeader extends Widgets {
  search_button: HTMLDivElement;
  buscador: any;
  async init() {
    if (window.BuscadorWidget) {
      this.start();
    } else {
      this.parent.element.addEventListener(EHELP_EVENTS.EHELP_SCRIPT_LOADED, e =>{
        console.log(e);
        this.start();
      })
    }
  }
  async start() {
    this.buscador = new BuscadorWidget(".buscador-qelp");
    await this.buscador.init({
      api: this.parent.settings.buscador.api[this.parent.environment],
      label: this.parent.settings.buscador.label,
      placeholder: this.parent.settings.buscador.placeholder,
      close: this.parent.settings.buscador.close,
      back: this.parent.settings.buscador.back,
      force_modal: this.parent.settings.buscador.force_modal,
      result_list: this.parent.settings.buscador.result_list,
      list: this.parent.settings.buscador.list,
      max: this.parent.settings.buscador.max,
    });
    this.search_button = this.element.querySelector(".ehelp-search-btn");
    this.search_button.innerHTML = this.parent.settings.buscador.label;
    this.search_button.addEventListener("click", (e: Event) => {
      this.buscador.appModal();
    });
  }
}
