var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div\r\n      class=\"usecase-step step-"
    + alias4(((helper = (helper = lookupProperty(helpers,"index") || (data && lookupProperty(data,"index"))) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"index","hash":{},"data":data,"loc":{"start":{"line":13,"column":31},"end":{"line":13,"column":41}}}) : helper)))
    + "\"\r\n      data-step=\""
    + alias4((lookupProperty(helpers,"math")||(depth0 && lookupProperty(depth0,"math"))||alias2).call(alias1,(data && lookupProperty(data,"index")),"+",1,{"name":"math","hash":{},"data":data,"loc":{"start":{"line":14,"column":17},"end":{"line":14,"column":38}}}))
    + "\"\r\n      animation=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"animation") || (depth0 != null ? lookupProperty(depth0,"animation") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"animation","hash":{},"data":data,"loc":{"start":{"line":15,"column":17},"end":{"line":15,"column":30}}}) : helper)))
    + "\"\r\n    >\r\n      <div class=\"content\">\r\n        <div class=\"content-wrapper\">\r\n          <div class=\"step-number\">\r\n            "
    + alias4((lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"pages/usecase/content/step-number",{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":20,"column":12},"end":{"line":20,"column":61}}}))
    + " "
    + alias4((lookupProperty(helpers,"math")||(depth0 && lookupProperty(depth0,"math"))||alias2).call(alias1,(data && lookupProperty(data,"index")),"+",1,{"name":"math","hash":{},"data":data,"loc":{"start":{"line":20,"column":62},"end":{"line":20,"column":83}}}))
    + "\r\n          </div>\r\n          <div class=\"step-content\">\r\n            "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"text") || (depth0 != null ? lookupProperty(depth0,"text") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"text","hash":{},"data":data,"loc":{"start":{"line":23,"column":12},"end":{"line":23,"column":22}}}) : helper))) != null ? stack1 : "")
    + "\r\n          </div>\r\n        </div>\r\n      </div>\r\n\r\n      <div class=\"widget usecase-step-image usecase-step-image-"
    + alias4(((helper = (helper = lookupProperty(helpers,"index") || (data && lookupProperty(data,"index"))) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"index","hash":{},"data":data,"loc":{"start":{"line":28,"column":63},"end":{"line":28,"column":73}}}) : helper)))
    + "\">\r\n\r\n\r\n        <!-- Wrapped Start Screen and Device Image-->\r\n"
    + ((stack1 = (lookupProperty(helpers,"ifEquals")||(depth0 && lookupProperty(depth0,"ifEquals"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"hardwareImage") : depth0),true,{"name":"ifEquals","hash":{},"fn":container.program(2, data, 0, blockParams, depths),"inverse":container.program(5, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":32,"column":10},"end":{"line":73,"column":23}}})) != null ? stack1 : "")
    + "        <!-- Wrapped Start Screen and Device Image-->\r\n      </div>\r\n\r\n    </div>\r\n\r\n";
},"2":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, alias5=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"image\" \r\n                data-hardware-image="
    + alias4(((helper = (helper = lookupProperty(helpers,"hardwareImage") || (depth0 != null ? lookupProperty(depth0,"hardwareImage") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"hardwareImage","hash":{},"data":data,"loc":{"start":{"line":34,"column":36},"end":{"line":34,"column":53}}}) : helper)))
    + " \r\n                data-has-wrapper=\""
    + alias4(alias5(((stack1 = (depths[1] != null ? lookupProperty(depths[1],"device") : depths[1])) != null ? lookupProperty(stack1,"hasWrapper") : stack1), depth0))
    + "\"\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"coordinates") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":36,"column":16},"end":{"line":39,"column":23}}})) != null ? stack1 : "")
    + "                data-screen-size-x=\""
    + alias4(alias5(((stack1 = ((stack1 = ((stack1 = (depths[1] != null ? lookupProperty(depths[1],"device") : depths[1])) != null ? lookupProperty(stack1,"deviceImage") : stack1)) != null ? lookupProperty(stack1,"screenSize") : stack1)) != null ? lookupProperty(stack1,"x") : stack1), depth0))
    + "\"\r\n                data-screen-size-y=\""
    + alias4(alias5(((stack1 = ((stack1 = ((stack1 = (depths[1] != null ? lookupProperty(depths[1],"device") : depths[1])) != null ? lookupProperty(stack1,"deviceImage") : stack1)) != null ? lookupProperty(stack1,"screenSize") : stack1)) != null ? lookupProperty(stack1,"y") : stack1), depth0))
    + "\"\r\n                data-image-size-x=\""
    + alias4(alias5(((stack1 = ((stack1 = ((stack1 = (depths[1] != null ? lookupProperty(depths[1],"device") : depths[1])) != null ? lookupProperty(stack1,"deviceImage") : stack1)) != null ? lookupProperty(stack1,"imageSize") : stack1)) != null ? lookupProperty(stack1,"x") : stack1), depth0))
    + "\"\r\n                data-image-size-y=\""
    + alias4(alias5(((stack1 = ((stack1 = ((stack1 = (depths[1] != null ? lookupProperty(depths[1],"device") : depths[1])) != null ? lookupProperty(stack1,"deviceImage") : stack1)) != null ? lookupProperty(stack1,"imageSize") : stack1)) != null ? lookupProperty(stack1,"y") : stack1), depth0))
    + "\"\r\n                data-screen-position-x=\""
    + alias4(alias5(((stack1 = ((stack1 = ((stack1 = (depths[1] != null ? lookupProperty(depths[1],"device") : depths[1])) != null ? lookupProperty(stack1,"deviceImage") : stack1)) != null ? lookupProperty(stack1,"screenPosition") : stack1)) != null ? lookupProperty(stack1,"x") : stack1), depth0))
    + "\"\r\n                data-screen-position-y=\""
    + alias4(alias5(((stack1 = ((stack1 = ((stack1 = (depths[1] != null ? lookupProperty(depths[1],"device") : depths[1])) != null ? lookupProperty(stack1,"deviceImage") : stack1)) != null ? lookupProperty(stack1,"screenPosition") : stack1)) != null ? lookupProperty(stack1,"y") : stack1), depth0))
    + "\"\r\n                data-offset-x=\""
    + alias4(alias5(((stack1 = ((stack1 = ((stack1 = (depths[1] != null ? lookupProperty(depths[1],"device") : depths[1])) != null ? lookupProperty(stack1,"deviceImage") : stack1)) != null ? lookupProperty(stack1,"offset") : stack1)) != null ? lookupProperty(stack1,"x") : stack1), depth0))
    + "\"\r\n                data-offset-y=\""
    + alias4(alias5(((stack1 = ((stack1 = ((stack1 = (depths[1] != null ? lookupProperty(depths[1],"device") : depths[1])) != null ? lookupProperty(stack1,"deviceImage") : stack1)) != null ? lookupProperty(stack1,"offset") : stack1)) != null ? lookupProperty(stack1,"y") : stack1), depth0))
    + "\">\r\n                <div class=\"image-wrapper\">\r\n                    <img loading=\"lazy\" class=\"hardware\" src=\""
    + alias4(alias5(((stack1 = ((stack1 = (depths[1] != null ? lookupProperty(depths[1],"device") : depths[1])) != null ? lookupProperty(stack1,"deviceImage") : stack1)) != null ? lookupProperty(stack1,"imageURL") : stack1), depth0))
    + "\" alt=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":49,"column":103},"end":{"line":49,"column":111}}}) : helper)))
    + "\">\r\n                    <img loading=\"lazy\" class=\"startScreen\" src=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"imageURL") || (depth0 != null ? lookupProperty(depth0,"imageURL") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"imageURL","hash":{},"data":data,"loc":{"start":{"line":50,"column":65},"end":{"line":50,"column":77}}}) : helper)))
    + "\" alt=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":50,"column":84},"end":{"line":50,"column":92}}}) : helper)))
    + "\">\r\n                </div>\r\n            </div>\r\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                data-coordinates-x=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"coordinates") : depth0)) != null ? lookupProperty(stack1,"left") : stack1), depth0))
    + "\"\r\n                data-coordinates-y=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"coordinates") : depth0)) != null ? lookupProperty(stack1,"top") : stack1), depth0))
    + "\"\r\n";
},"5":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, alias5=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"image\" \r\n                data-hardware-image="
    + alias4(((helper = (helper = lookupProperty(helpers,"hardwareImage") || (depth0 != null ? lookupProperty(depth0,"hardwareImage") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"hardwareImage","hash":{},"data":data,"loc":{"start":{"line":55,"column":36},"end":{"line":55,"column":53}}}) : helper)))
    + " \r\n                data-has-wrapper=\""
    + alias4(alias5(((stack1 = (depths[1] != null ? lookupProperty(depths[1],"device") : depths[1])) != null ? lookupProperty(stack1,"hasWrapper") : stack1), depth0))
    + "\"\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"coordinates") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":57,"column":16},"end":{"line":60,"column":23}}})) != null ? stack1 : "")
    + "                data-screen-size-x=\""
    + alias4(alias5(((stack1 = ((stack1 = ((stack1 = (depths[1] != null ? lookupProperty(depths[1],"device") : depths[1])) != null ? lookupProperty(stack1,"deviceImage") : stack1)) != null ? lookupProperty(stack1,"screenSize") : stack1)) != null ? lookupProperty(stack1,"x") : stack1), depth0))
    + "\"\r\n                data-screen-size-y=\""
    + alias4(alias5(((stack1 = ((stack1 = ((stack1 = (depths[1] != null ? lookupProperty(depths[1],"device") : depths[1])) != null ? lookupProperty(stack1,"deviceImage") : stack1)) != null ? lookupProperty(stack1,"screenSize") : stack1)) != null ? lookupProperty(stack1,"y") : stack1), depth0))
    + "\"\r\n                data-image-size-x=\""
    + alias4(alias5(((stack1 = ((stack1 = ((stack1 = (depths[1] != null ? lookupProperty(depths[1],"device") : depths[1])) != null ? lookupProperty(stack1,"deviceImage") : stack1)) != null ? lookupProperty(stack1,"imageSize") : stack1)) != null ? lookupProperty(stack1,"x") : stack1), depth0))
    + "\"\r\n                data-image-size-y=\""
    + alias4(alias5(((stack1 = ((stack1 = ((stack1 = (depths[1] != null ? lookupProperty(depths[1],"device") : depths[1])) != null ? lookupProperty(stack1,"deviceImage") : stack1)) != null ? lookupProperty(stack1,"imageSize") : stack1)) != null ? lookupProperty(stack1,"y") : stack1), depth0))
    + "\"\r\n                data-screen-position-x=\""
    + alias4(alias5(((stack1 = ((stack1 = ((stack1 = (depths[1] != null ? lookupProperty(depths[1],"device") : depths[1])) != null ? lookupProperty(stack1,"deviceImage") : stack1)) != null ? lookupProperty(stack1,"screenPosition") : stack1)) != null ? lookupProperty(stack1,"x") : stack1), depth0))
    + "\"\r\n                data-screen-position-y=\""
    + alias4(alias5(((stack1 = ((stack1 = ((stack1 = (depths[1] != null ? lookupProperty(depths[1],"device") : depths[1])) != null ? lookupProperty(stack1,"deviceImage") : stack1)) != null ? lookupProperty(stack1,"screenPosition") : stack1)) != null ? lookupProperty(stack1,"y") : stack1), depth0))
    + "\"\r\n                data-offset-x=\""
    + alias4(alias5(((stack1 = ((stack1 = ((stack1 = (depths[1] != null ? lookupProperty(depths[1],"device") : depths[1])) != null ? lookupProperty(stack1,"deviceImage") : stack1)) != null ? lookupProperty(stack1,"offset") : stack1)) != null ? lookupProperty(stack1,"x") : stack1), depth0))
    + "\"\r\n                data-offset-y=\""
    + alias4(alias5(((stack1 = ((stack1 = ((stack1 = (depths[1] != null ? lookupProperty(depths[1],"device") : depths[1])) != null ? lookupProperty(stack1,"deviceImage") : stack1)) != null ? lookupProperty(stack1,"offset") : stack1)) != null ? lookupProperty(stack1,"y") : stack1), depth0))
    + "\">\r\n                <div class=\"image-wrapper\">\r\n                    <img loading=\"lazy\" class=\"startScreen\" src=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"imageURL") || (depth0 != null ? lookupProperty(depth0,"imageURL") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"imageURL","hash":{},"data":data,"loc":{"start":{"line":70,"column":65},"end":{"line":70,"column":77}}}) : helper)))
    + "\" alt=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":70,"column":84},"end":{"line":70,"column":92}}}) : helper)))
    + "\">\r\n                </div>\r\n            </div>\r\n";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"col-md-6 col-sm-12\">\r\n      "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"related_topics") || (depth0 != null ? lookupProperty(depth0,"related_topics") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"related_topics","hash":{},"data":data,"loc":{"start":{"line":89,"column":6},"end":{"line":89,"column":26}}}) : helper))) != null ? stack1 : "")
    + "\r\n    </div>\r\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.lambda, alias5=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = ((helper = (helper = lookupProperty(helpers,"simple_header") || (depth0 != null ? lookupProperty(depth0,"simple_header") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"simple_header","hash":{},"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":1,"column":19}}}) : helper))) != null ? stack1 : "")
    + "\r\n<div class=\"widget usecase-title text-center\">\r\n  <h1 class=\"page-title\">"
    + alias5(alias4(((stack1 = (depth0 != null ? lookupProperty(depth0,"topic") : depth0)) != null ? lookupProperty(stack1,"topicName") : stack1), depth0))
    + "</h1>\r\n  <div class=\"horizontal-decorator\"></div>\r\n  <p class=\"page-description\">\r\n    "
    + alias5(alias4(((stack1 = (depth0 != null ? lookupProperty(depth0,"topic") : depth0)) != null ? lookupProperty(stack1,"description") : stack1), depth0))
    + "\r\n  </p>\r\n</div>\r\n\r\n<div class=\"widget topic-usecase\">\r\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"usecase") : depth0)) != null ? lookupProperty(stack1,"steps") : stack1),{"name":"each","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":11,"column":2},"end":{"line":79,"column":11}}})) != null ? stack1 : "")
    + "</div>\r\n\r\n"
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"back_to_top") || (depth0 != null ? lookupProperty(depth0,"back_to_top") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"back_to_top","hash":{},"data":data,"loc":{"start":{"line":82,"column":0},"end":{"line":82,"column":17}}}) : helper))) != null ? stack1 : "")
    + "\r\n<div class=\"row related_topics-prs g-0\">\r\n  <div class=\"col-md-6 col-sm-12\">\r\n    "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"prs") || (depth0 != null ? lookupProperty(depth0,"prs") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"prs","hash":{},"data":data,"loc":{"start":{"line":85,"column":4},"end":{"line":85,"column":13}}}) : helper))) != null ? stack1 : "")
    + "\r\n  </div>\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"related_topics") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":87,"column":2},"end":{"line":91,"column":9}}})) != null ? stack1 : "")
    + "</div>\r\n";
},"useData":true,"useDepths":true});