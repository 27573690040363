var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"widget trails full-width\">\r\n  <h2>"
    + alias3((lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"pages/home/widgets/banner/title",{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":2,"column":6},"end":{"line":2,"column":53}}}))
    + "</h2>\r\n  <p class=\"lead\">\r\n    "
    + alias3((lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"pages/home/widgets/banner/description",{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":4,"column":4},"end":{"line":4,"column":57}}}))
    + "\r\n  </p>\r\n\r\n  <a href=\""
    + alias3(((helper = (helper = lookupProperty(helpers,"root") || (depth0 != null ? lookupProperty(depth0,"root") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"root","hash":{},"data":data,"loc":{"start":{"line":7,"column":11},"end":{"line":7,"column":19}}}) : helper)))
    + "trilhas\" class=\"forward-btn\">"
    + alias3((lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"pages/home/widgets/banner/cta-label",{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":7,"column":48},"end":{"line":7,"column":99}}}))
    + "</a>\r\n</div>";
},"useData":true});