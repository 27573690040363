var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), alias4=container.hooks.helperMissing, alias5="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      <div class=\"accordion-item\" data-device-type=\""
    + alias2(alias1(((stack1 = (depths[1] != null ? lookupProperty(depths[1],"deviceType") : depths[1])) != null ? lookupProperty(stack1,"path") : stack1), depth0))
    + "\" data-device-name=\""
    + alias2(alias1(((stack1 = (depths[1] != null ? lookupProperty(depths[1],"faq") : depths[1])) != null ? lookupProperty(stack1,"name") : stack1), depth0))
    + "\">\r\n        <h2 class=\"accordion-header\" id=\""
    + alias2(((helper = (helper = lookupProperty(helpers,"topicPath") || (depth0 != null ? lookupProperty(depth0,"topicPath") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"topicPath","hash":{},"data":data,"blockParams":blockParams,"loc":{"start":{"line":9,"column":41},"end":{"line":9,"column":54}}}) : helper)))
    + "-head\">\r\n          <button\r\n            class=\"accordion-button collapsed\"\r\n            type=\"button\"\r\n            data-bs-toggle=\"collapse\"\r\n            data-bs-target=\"#"
    + alias2(((helper = (helper = lookupProperty(helpers,"topicPath") || (depth0 != null ? lookupProperty(depth0,"topicPath") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"topicPath","hash":{},"data":data,"blockParams":blockParams,"loc":{"start":{"line":14,"column":29},"end":{"line":14,"column":42}}}) : helper)))
    + "\"\r\n            aria-expanded=\"false\"\r\n            aria-controls=\""
    + alias2(((helper = (helper = lookupProperty(helpers,"topicPath") || (depth0 != null ? lookupProperty(depth0,"topicPath") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"topicPath","hash":{},"data":data,"blockParams":blockParams,"loc":{"start":{"line":16,"column":27},"end":{"line":16,"column":40}}}) : helper)))
    + "\"\r\n          >\r\n            <p class=\"topic-name\">"
    + alias2(((helper = (helper = lookupProperty(helpers,"topicName") || (depth0 != null ? lookupProperty(depth0,"topicName") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"topicName","hash":{},"data":data,"blockParams":blockParams,"loc":{"start":{"line":18,"column":34},"end":{"line":18,"column":47}}}) : helper)))
    + "</p>\r\n          </button>\r\n        </h2>\r\n        <div\r\n          id=\""
    + alias2(((helper = (helper = lookupProperty(helpers,"topicPath") || (depth0 != null ? lookupProperty(depth0,"topicPath") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"topicPath","hash":{},"data":data,"blockParams":blockParams,"loc":{"start":{"line":22,"column":14},"end":{"line":22,"column":27}}}) : helper)))
    + "\"\r\n          class=\"accordion-collapse collapse\"\r\n          aria-labelledby=\""
    + alias2(((helper = (helper = lookupProperty(helpers,"topicPath") || (depth0 != null ? lookupProperty(depth0,"topicPath") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"topicPath","hash":{},"data":data,"blockParams":blockParams,"loc":{"start":{"line":24,"column":27},"end":{"line":24,"column":40}}}) : helper)))
    + "-head\"\r\n          data-bs-parent=\"#faqaccordion\"\r\n        >\r\n          <div class=\"accordion-body\">\r\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias3,(depth0 != null ? lookupProperty(depth0,"steps") : depth0),{"name":"each","hash":{},"fn":container.program(2, data, 1, blockParams, depths),"inverse":container.noop,"data":data,"blockParams":blockParams,"loc":{"start":{"line":28,"column":12},"end":{"line":30,"column":21}}})) != null ? stack1 : "")
    + "          </div>\r\n        </div>\r\n      </div>\r\n";
},"2":function(container,depth0,helpers,partials,data,blockParams) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "              <p>"
    + ((stack1 = container.lambda(((stack1 = blockParams[0][0]) != null ? lookupProperty(stack1,"text") : stack1), depth0)) != null ? stack1 : "")
    + "</p>\r\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"widget faqs\">\r\n\r\n  <h2>"
    + container.escapeExpression((lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||container.hooks.helperMissing).call(alias1,"pages/home/widgets/faqs/title",{"name":"translate","hash":{},"data":data,"blockParams":blockParams,"loc":{"start":{"line":3,"column":6},"end":{"line":3,"column":51}}}))
    + "</h2>\r\n  <div class=\"horizontal-decorator\"></div>\r\n\r\n  <div class=\"accordion\" id=\"faqaccordion\">\r\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"faqs") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"blockParams":blockParams,"loc":{"start":{"line":7,"column":4},"end":{"line":34,"column":13}}})) != null ? stack1 : "")
    + "  </div>\r\n</div>";
},"useData":true,"useDepths":true,"useBlockParams":true});