var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = ((helper = (helper = lookupProperty(helpers,"simple_header") || (depth0 != null ? lookupProperty(depth0,"simple_header") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"simple_header","hash":{},"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":1,"column":19}}}) : helper))) != null ? stack1 : "")
    + "\r\n<div class=\"text-center\">\r\n  <h1 class=\"page-title\">"
    + container.escapeExpression((lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"pages/trilhas/title",{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":3,"column":25},"end":{"line":3,"column":60}}}))
    + "</h1>\r\n  <div class=\"horizontal-decorator\"></div>\r\n</div>\r\n"
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"profile_selector") || (depth0 != null ? lookupProperty(depth0,"profile_selector") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"profile_selector","hash":{},"data":data,"loc":{"start":{"line":6,"column":0},"end":{"line":6,"column":22}}}) : helper))) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"side_menu") || (depth0 != null ? lookupProperty(depth0,"side_menu") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"side_menu","hash":{},"data":data,"loc":{"start":{"line":7,"column":0},"end":{"line":7,"column":15}}}) : helper))) != null ? stack1 : "")
    + "\r\n\r\n\r\n"
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"trail_list") || (depth0 != null ? lookupProperty(depth0,"trail_list") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"trail_list","hash":{},"data":data,"loc":{"start":{"line":10,"column":0},"end":{"line":10,"column":16}}}) : helper))) != null ? stack1 : "");
},"useData":true});