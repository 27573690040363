var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), alias4=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"trail\" data-id=\""
    + alias2(alias1(((stack1 = blockParams[0][0]) != null ? lookupProperty(stack1,"id") : stack1), depth0))
    + "\" data-name=\""
    + alias2(alias1(((stack1 = blockParams[0][0]) != null ? lookupProperty(stack1,"name") : stack1), depth0))
    + "\">\r\n      <h2>"
    + alias2(alias1(((stack1 = blockParams[0][0]) != null ? lookupProperty(stack1,"name") : stack1), depth0))
    + "</h2>\r\n      <div class=\"list\" data-trail-id=\""
    + alias2(alias1(((stack1 = blockParams[0][0]) != null ? lookupProperty(stack1,"id") : stack1), depth0))
    + "\">\r\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias3,((stack1 = (depths[1] != null ? lookupProperty(depths[1],"trails") : depths[1])) != null ? lookupProperty(stack1,"content") : stack1),{"name":"each","hash":{},"fn":container.program(2, data, 1, blockParams, depths),"inverse":container.noop,"data":data,"blockParams":blockParams,"loc":{"start":{"line":6,"column":8},"end":{"line":36,"column":17}}})) != null ? stack1 : "")
    + "      </div>\r\n      <div class=\"trail-nav\">\r\n        <div class=\"panel panel-prev inactive\">\r\n          <i class=\"bi bi-play-fill prev\" data-trail-id=\""
    + alias2(alias1(((stack1 = blockParams[0][0]) != null ? lookupProperty(stack1,"id") : stack1), depth0))
    + "\"></i>\r\n          <p class=\"label\">"
    + alias2((lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias4).call(alias3,"widgets/trail-list/trail-nav/panel-prev",{"name":"translate","hash":{},"data":data,"blockParams":blockParams,"loc":{"start":{"line":41,"column":27},"end":{"line":41,"column":82}}}))
    + "<br><span class=\"numbers\"></span></p>\r\n        </div>\r\n        <div class=\"panel panel-next\">\r\n          <p class=\"label\">"
    + alias2((lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias4).call(alias3,"widgets/trail-list/trail-nav/panel-next",{"name":"translate","hash":{},"data":data,"blockParams":blockParams,"loc":{"start":{"line":44,"column":27},"end":{"line":44,"column":82}}}))
    + "<br><span class=\"numbers\"></span></p>\r\n          <i class=\"bi bi-play-fill next\" data-trail-id=\""
    + alias2(alias1(((stack1 = blockParams[0][0]) != null ? lookupProperty(stack1,"id") : stack1), depth0))
    + "\"></i>\r\n        </div>\r\n      </div>\r\n    </div>\r\n\r\n";
},"2":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = (lookupProperty(helpers,"ifEquals")||(depth0 && lookupProperty(depth0,"ifEquals"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = blockParams[0][0]) != null ? lookupProperty(stack1,"trail") : stack1),((stack1 = blockParams[1][0]) != null ? lookupProperty(stack1,"id") : stack1),{"name":"ifEquals","hash":{},"fn":container.program(3, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"blockParams":blockParams,"loc":{"start":{"line":7,"column":12},"end":{"line":35,"column":25}}})) != null ? stack1 : "");
},"3":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"content\" data-profiles=\""
    + alias3((lookupProperty(helpers,"tostring")||(depth0 && lookupProperty(depth0,"tostring"))||alias2).call(alias1,((stack1 = blockParams[1][0]) != null ? lookupProperty(stack1,"profiles") : stack1),{"name":"tostring","hash":{},"data":data,"blockParams":blockParams,"loc":{"start":{"line":8,"column":48},"end":{"line":8,"column":77}}}))
    + "\">\r\n"
    + ((stack1 = (lookupProperty(helpers,"ifEquals")||(depth0 && lookupProperty(depth0,"ifEquals"))||alias2).call(alias1,((stack1 = blockParams[1][0]) != null ? lookupProperty(stack1,"type") : stack1),"youtube",{"name":"ifEquals","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.program(9, data, 0, blockParams, depths),"data":data,"blockParams":blockParams,"loc":{"start":{"line":9,"column":16},"end":{"line":21,"column":29}}})) != null ? stack1 : "")
    + "                <div class=\"description\">\r\n                    <span class=\"title\">"
    + alias3(container.lambda(((stack1 = blockParams[1][0]) != null ? lookupProperty(stack1,"title") : stack1), depth0))
    + "</span>\r\n                    <div class=\"profiles\">\r\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,((stack1 = blockParams[1][0]) != null ? lookupProperty(stack1,"profiles") : stack1),{"name":"each","hash":{},"fn":container.program(11, data, 1, blockParams, depths),"inverse":container.noop,"data":data,"blockParams":blockParams,"loc":{"start":{"line":25,"column":22},"end":{"line":31,"column":31}}})) != null ? stack1 : "")
    + "                    </div>\r\n                </div>\r\n            </div>\r\n";
},"4":function(container,depth0,helpers,partials,data,blockParams) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.lambda, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\"thumbnail video"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = blockParams[2][0]) != null ? lookupProperty(stack1,"watched") : stack1),{"name":"if","hash":{},"fn":container.program(5, data, 0, blockParams),"inverse":container.noop,"data":data,"blockParams":blockParams,"loc":{"start":{"line":10,"column":43},"end":{"line":10,"column":81}}})) != null ? stack1 : "")
    + "\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = blockParams[2][0]) != null ? lookupProperty(stack1,"watched") : stack1),{"name":"if","hash":{},"fn":container.program(7, data, 0, blockParams),"inverse":container.noop,"data":data,"blockParams":blockParams,"loc":{"start":{"line":10,"column":83},"end":{"line":10,"column":129}}})) != null ? stack1 : "")
    + " data-type=\""
    + alias3(alias2(((stack1 = blockParams[2][0]) != null ? lookupProperty(stack1,"type") : stack1), depth0))
    + "\"\r\n                    data-order = "
    + alias3(alias2(((stack1 = blockParams[2][0]) != null ? lookupProperty(stack1,"order") : stack1), depth0))
    + "\r\n                    data-publico_cod = "
    + alias3(alias2(((stack1 = blockParams[2][0]) != null ? lookupProperty(stack1,"publico_cod") : stack1), depth0))
    + "\r\n                   data-video_cod=\""
    + alias3(alias2(((stack1 = ((stack1 = blockParams[2][0]) != null ? lookupProperty(stack1,"youtube_video") : stack1)) != null ? lookupProperty(stack1,"video_cod") : stack1), depth0))
    + "\" \r\n                    data-url=\""
    + alias3(alias2(((stack1 = blockParams[2][0]) != null ? lookupProperty(stack1,"url") : stack1), depth0))
    + "\" \r\n                    data-video_id=\""
    + alias3(alias2(((stack1 = blockParams[2][0]) != null ? lookupProperty(stack1,"video_id") : stack1), depth0))
    + "\" \r\n                      data-title=\""
    + alias3(alias2(((stack1 = blockParams[2][0]) != null ? lookupProperty(stack1,"title") : stack1), depth0))
    + "\" data-trail-id=\""
    + alias3(alias2(((stack1 = blockParams[3][0]) != null ? lookupProperty(stack1,"id") : stack1), depth0))
    + "\">\r\n                  <div class=\"play-btn\"><i class=\"bi bi-play-btn-fill\"></i></div>\r\n                </div>\r\n";
},"5":function(container,depth0,helpers,partials,data) {
    return " watched";
},"7":function(container,depth0,helpers,partials,data) {
    return "data-watched=\"1\"";
},"9":function(container,depth0,helpers,partials,data,blockParams) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\"thumbnail pdf\" data-type=\""
    + alias2(alias1(((stack1 = blockParams[2][0]) != null ? lookupProperty(stack1,"type") : stack1), depth0))
    + "\" data-url=\""
    + alias2(alias1(((stack1 = blockParams[2][0]) != null ? lookupProperty(stack1,"url") : stack1), depth0))
    + "\" data-title=\""
    + alias2(alias1(((stack1 = blockParams[2][0]) != null ? lookupProperty(stack1,"title") : stack1), depth0))
    + "\" data-trail-id=\""
    + alias2(alias1(((stack1 = blockParams[3][0]) != null ? lookupProperty(stack1,"id") : stack1), depth0))
    + "\"></div>\r\n";
},"11":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depths[3] != null ? lookupProperty(depths[3],"trails") : depths[3])) != null ? lookupProperty(stack1,"profiles") : stack1),{"name":"each","hash":{},"fn":container.program(12, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"blockParams":blockParams,"loc":{"start":{"line":26,"column":24},"end":{"line":30,"column":33}}})) != null ? stack1 : "");
},"12":function(container,depth0,helpers,partials,data,blockParams) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = (lookupProperty(helpers,"ifEquals")||(depth0 && lookupProperty(depth0,"ifEquals"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),blockParams[1][0],(depth0 != null ? lookupProperty(depth0,"id") : depth0),{"name":"ifEquals","hash":{},"fn":container.program(13, data, 0, blockParams),"inverse":container.noop,"data":data,"blockParams":blockParams,"loc":{"start":{"line":27,"column":26},"end":{"line":29,"column":39}}})) != null ? stack1 : "");
},"13":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <span class=\"badge rounded-pill text-bg-primary "
    + alias4(((helper = (helper = lookupProperty(helpers,"path") || (depth0 != null ? lookupProperty(depth0,"path") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"path","hash":{},"data":data,"loc":{"start":{"line":28,"column":76},"end":{"line":28,"column":84}}}) : helper)))
    + "\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":28,"column":86},"end":{"line":28,"column":94}}}) : helper)))
    + "</span>\r\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"widget trail-list full-width\">\r\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"trails") : depth0)) != null ? lookupProperty(stack1,"trails") : stack1),{"name":"each","hash":{},"fn":container.program(1, data, 1, blockParams, depths),"inverse":container.noop,"data":data,"blockParams":blockParams,"loc":{"start":{"line":2,"column":2},"end":{"line":50,"column":11}}})) != null ? stack1 : "")
    + "</div>";
},"useData":true,"useDepths":true,"useBlockParams":true});