import { App } from "..";
import { Widgets } from "../../../widgets/Widgets";

export class FAQs extends Widgets {
  list: NodeListOf<HTMLDivElement>;
  page: number;
  itemPerPage: number;
  next_page: HTMLElement;
  prev_page: HTMLElement;
  parent: App;
  constructor(selector: string) {
    super(selector);
    this.list = this.element.querySelectorAll(".accordion-item");

    this.list.forEach(item=>{
      item.querySelector('.accordion-header').addEventListener("click", (e)=>{
        const target = e.currentTarget as HTMLElement;
        const topicName = target.querySelector(".topic-name").innerHTML;
        this.parent.dataLayer({
          event: 'faqload',
          pageType: this.parent.translate("widgets/faq/pageType"),
          pageTitle: this.parent.translate("widgets/faq/pageTitle") + ` - ${topicName}`,
          contentType: (target.dataset && target.dataset.deviceType) ? target.dataset.deviceType : '',
          contentVariety: target.dataset.deviceName,
          contentTopic: topicName,
          userIdentification: this.parent.userCode
        })
      })
    })
  }
}
