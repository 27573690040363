import { EHELP_EVENTS } from "../eHelp";
import "./qlickr.scss";
import { Widgets } from "./Widgets";

export class Qlickr extends Widgets {
  qlickr: HTMLDivElement;
  constructor(step_selector: string, image_selector: string) {
    super(image_selector);

    let image: HTMLElement = this.element.querySelector(".image");
    this.qlickr = document.createElement("div");
    this.qlickr.classList.add("qlickr-holder");
    let qlickr = document.createElement("div");
    qlickr.classList.add("qlickr");
    this.qlickr.append(qlickr);
    this.qlickr.classList.add("qlickr-holder");
    this.qlickr.style.position = "absolute";
    let hardware = image.querySelector(".hardware");

    if (hardware) {
      if (
        Number(image.dataset.screenSizeX) > 0 &&
        Number(image.dataset.screenSizeY) > 0
      ) {
        this.qlickr.style.left =
          Number(image.dataset.coordinatesX) +
          Number(image.dataset.screenPositionX) +
          "px";
        this.qlickr.style.top =
          Number(image.dataset.coordinatesY) +
          Number(image.dataset.screenPositionY) +
          "px";
      }
    } else {
      this.qlickr.style.left = image.dataset.coordinatesX + "px";
      this.qlickr.style.top = image.dataset.coordinatesY + "px";
    }
    this.element.querySelector(".image-wrapper").append(this.qlickr);

    this.element.addEventListener(EHELP_EVENTS.EHELP_IMG_WRAP_RESIZED, (e) => {
      this.wrappedImageResizedHandler(e as CustomEvent);
    });
  }
  wrappedImageResizedHandler(e: CustomEvent) {

    if (e.detail.scale >= 1) return;
    this.element.addEventListener("click", (e) => {
      this.toogleZoom(e);
    });
    this.element.classList.add("zoomable")        
    let scale = (1 + (1 - e.detail.scale)) * 2;
    this.qlickr.style.transformOrigin = "top left";
    this.qlickr.style.transform = `scale(${scale})`;
  }
  toogleZoom(e: MouseEvent) {
    let target = e.currentTarget as HTMLElement;
    let wrapper: HTMLDivElement = target.querySelector(".image");
    let img: HTMLDivElement = target.querySelector(".image-wrapper");
    let qlicker: HTMLDivElement = target.querySelector(".qlickr-holder");

    
    if (!qlicker) return;
    if (!img.style.transform) return;

    if (!img.classList.contains("zoomed")) {
      img.dataset.transform = img.style.transform;
      qlicker.dataset.transform = qlicker.style.transform;
      img.dataset.top = img.style.top;
      img.dataset.left = img.style.left;

      img.style.transform = "scale(1)";
      qlicker.style.transform = "scale(1)";

      let left = Number(qlicker.style.left.replace(/[^0-9]/g, ""));
      let top = Number(qlicker.style.top.replace(/[^0-9]/g, ""));

      let offY = wrapper.offsetHeight / 2;
      let offX = wrapper.offsetWidth / 2;

      img.style.left = (0 - left + offX) + "px";
      img.style.top = (0 - top + offY) + "px";


      img.classList.add("zoomed");
    } else {
      img.style.transform = img.dataset.transform;
      qlicker.style.transform = qlicker.dataset.transform;
      img.style.top = img.dataset.top;
      img.style.left = img.dataset.left;

      delete img.dataset.transform;
      delete qlicker.dataset.transform;
      img.classList.remove("zoomed");
    }
  }
}
