import { App } from "..";
import { Widgets } from "../../../widgets/Widgets";

declare global {
  var YT: {
    Player: any;
    PlayerState: any;
  };
}

interface PlayerEvent {
  data: any;
  target: {
    playVideo: Function;
  };
}

export class TrailList extends Widgets {
  content: NodeListOf<HTMLDivElement>;
  trails: NodeListOf<HTMLDivElement>;
  modal: HTMLElement;
  player: HTMLElement;
  ytplayer: {
    getPlayerState: Function
    playerInfo: {
      currentTime: number
    }
  };
  parent: App;
  currentVideo: HTMLDivElement;
  constructor(selector: string) {
    super(selector);

    this.trails = this.element.querySelectorAll(".trail");
    this.content = this.element.querySelectorAll(".content");

    this.trails.forEach((elem) => {
      elem.querySelector(".content").classList.add("active");
      const contents = elem.querySelectorAll(".content .thumbnail");
      const trail_nav = elem.querySelector(".trail-nav");

      trail_nav.querySelectorAll(".numbers")?.forEach((span) => {
        span.innerHTML = "1/" + contents.length;
      });

      const next_btn = elem.querySelector(".panel .next");
      const prev_btn = elem.querySelector(".panel .prev");
      next_btn.addEventListener("click", (e) => {
        this.nextContent(e);
      });
      prev_btn.addEventListener("click", (e) => {
        this.prevContent(e);
      });
      const panel_prev = trail_nav.querySelector(".panel-prev");
      const panel_next = trail_nav.querySelector(".panel-next");

      if (contents.length === 1) {
        panel_prev.classList.add("inactive");
        panel_next.classList.add("inactive");
      }
    });

    this.content.forEach((elem) => {
      const thumbnail: HTMLDivElement = elem.querySelector(".thumbnail");
      
      const thumb = document.createElement("div");
      const img = document.createElement("img");

      if (thumbnail.dataset.type == "youtube") {
        thumb.classList.add("video-thumbnail");
        const params = thumbnail.dataset.url.split("/");
        const img_url = `https://i.ytimg.com/vi_webp/${params.pop()}/mqdefault.webp`;
        img.src = img_url;
      } else {
        thumb.classList.add("file-thumbnail", thumbnail.dataset.type);
      }

      thumb.append(img);
      thumbnail.append(thumb);

      thumbnail.addEventListener("click", (e) => {
        const target = e.currentTarget as HTMLDivElement;
        this.parent.router.navigateTo("/trilhas/video-player/"+target.dataset.video_cod);
      });
    });
  }
  gotoContent(e: Event, n: number) {
    const button: HTMLElement = e.currentTarget as HTMLElement;
    const trail = this.element.querySelector(
      `.trail[data-id='${button.dataset.trailId}']`
    );
    const list: HTMLElement = trail.querySelector(".list");
    const contents = list.querySelectorAll(".content");

    const step = list.scrollWidth / contents.length;
    let current = 0;
    for (let i = 0; i < contents.length; i++) {
      if (contents[i].classList.contains("active")) {
        current = i;
      }
    }

    if (current + n < contents.length && current + n >= 0) current += n;

    contents.forEach((elem) => {
      elem.classList.remove("active");
    });
    contents[current].classList.add("active");

    list.scrollTo({
      left: current * step,
      behavior: "smooth",
    });
    this.updatePanel(list);
  }
  updatePanel(list: HTMLElement) {
    const trail = this.element.querySelector(
      `.trail[data-id='${list.dataset.trailId}']`
    );
    const trail_nav = trail.querySelector(".trail-nav");
    const contents = list.querySelectorAll(".content");
    const step = list.scrollWidth / contents.length;

    let current = 0;
    for (let i = 0; i < contents.length; i++) {
      if (contents[i].classList.contains("active")) {
        current = i;
      }
    }

    const panel_prev = trail_nav.querySelector(".panel-prev");
    const panel_next = trail_nav.querySelector(".panel-next");
    if (current === 0) {
      panel_prev.classList.add("inactive");
    } else {
      panel_prev.classList.remove("inactive");
    }
    if (current + 1 >= contents.length) {
      panel_next.classList.add("inactive");
    } else {
      panel_next.classList.remove("inactive");
    }

    // trail_nav.querySelector(".panel-prev .numbers").innerHTML = (current)  + "/" + contents.length;
    // trail_nav.querySelector(".panel-next .numbers").innerHTML = (current + 2)  + "/" + contents.length;
  }
  prevContent(e: Event) {
    this.gotoContent(e, -1);
  }
  nextContent(e: Event) {
    this.gotoContent(e, 1);
  }
}
