var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.lambda, alias5=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = ((helper = (helper = lookupProperty(helpers,"simple_header") || (depth0 != null ? lookupProperty(depth0,"simple_header") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"simple_header","hash":{},"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":1,"column":19}}}) : helper))) != null ? stack1 : "")
    + "\r\n<div class=\"widget device-title text-center\">\r\n  <h1 class=\"page-title\">"
    + alias5(alias4(((stack1 = (depth0 != null ? lookupProperty(depth0,"device") : depth0)) != null ? lookupProperty(stack1,"name") : stack1), depth0))
    + "</h1>\r\n  <div class=\"horizontal-decorator\"></div>\r\n    <p class=\"page-description\">"
    + alias5(alias4(((stack1 = (depth0 != null ? lookupProperty(depth0,"category") : depth0)) != null ? lookupProperty(stack1,"description") : stack1), depth0))
    + "</p>\r\n</div>\r\n\r\n"
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"tutorial_list") || (depth0 != null ? lookupProperty(depth0,"tutorial_list") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"tutorial_list","hash":{},"data":data,"loc":{"start":{"line":8,"column":0},"end":{"line":8,"column":19}}}) : helper))) != null ? stack1 : "");
},"useData":true});