import { Widgets } from "../../../widgets/Widgets";

export class SideMenu extends Widgets {
  init(): void {
    this.element.querySelectorAll("li").forEach((item) => {
      item.addEventListener("click", (e) => {
        const button = e.currentTarget as HTMLButtonElement;        
        let event = new CustomEvent(SIDE_MENU_ITEM_CLICKED, {
          bubbles: true,
          detail: {
            id: button.dataset.id,
          },
        });
        this.element.dispatchEvent(event);
      });
    });
  }
}

export const SIDE_MENU_ITEM_CLICKED = "SIDE_MENU_ITEM_CLICKED";
