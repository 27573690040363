import { eHelp, EHELP_EVENTS } from "../eHelp";

export class Widgets {
    element: HTMLElement;
    selector: string;
    parent: eHelp;
    constructor(selector: string) {
        try {
            this.element = document.querySelector(`.widget.${selector}`);
            if (!this.element) {
                console.warn(`${selector} not found. Maybe the CSS class widget is missing. `);
            }
        } catch (e) {
            console.warn(e);
            console.log(`.widget.${selector}`);
        }
        this.selector = selector;
        this.parent = null;
    }
    init() {}
    getCSSVariable(varName:string) {
        return getComputedStyle(document.documentElement).getPropertyValue(varName).trim();
    }
    getCSSVariableNumber(varName: string) {
        return Number(this.getCSSVariable(varName).replace(/[^0-9\.\,\-]/g, ""));
    }
    wrappDeviceImage(elem:HTMLElement) {
        let hardware = elem.querySelector(".hardware");
        let startscreen = elem.querySelector<HTMLElement>(".startScreen");
        let wrapper = elem.querySelector<HTMLElement>(".image-wrapper");
        if (hardware) {
            if (Number(elem.dataset.screenSizeX) > 0 && Number(elem.dataset.screenSizeY) > 0) {
                startscreen.style.width = elem.dataset.screenSizeX + "px";
                startscreen.style.height = elem.dataset.screenSizeY + "px";
                startscreen.style.left = elem.dataset.screenPositionX + "px";
                startscreen.style.top = elem.dataset.screenPositionY + "px";
            }

            let scale = 1;
            if (Number(elem.dataset.imageSizeX) > Number(elem.offsetWidth)
                && Number(elem.dataset.imageSizeY) > Number(elem.offsetHeight)) {
                if (Number(elem.dataset.imageSizeX) > Number(elem.dataset.imageSizeY)) {
                    scale = Number(elem.offsetWidth) / Number(elem.dataset.imageSizeX);
                } else {
                    scale = elem.offsetHeight / Number(elem.dataset.imageSizeY);
                }
            } else if (Number(elem.dataset.imageSizeX) > Number(elem.offsetWidth)) {
                scale = Number(elem.offsetWidth) / Number(elem.dataset.imageSizeX);
            } else {
                scale = Number(elem.offsetHeight) / Number(elem.dataset.imageSizeY);
            }
            if (scale > 1) scale = 1;
            wrapper.style.transform = `scale(${scale})`;
            wrapper.style.left = Math.floor((Number(elem.offsetWidth) / 2) - (Number(elem.dataset.imageSizeX) * scale / 2)) + "px";
            wrapper.style.top = Math.floor((Number(elem.offsetHeight) / 2) - (Number(elem.dataset.imageSizeY) * scale / 2)) + "px";
            let event = new CustomEvent(EHELP_EVENTS.EHELP_IMG_WRAP_RESIZED, {
                bubbles: true,
                detail: {
                    scale: scale
                }
            });

            this.element.dispatchEvent(event);
        } else {
            let startscreen = elem.querySelector<HTMLImageElement>(".startScreen");
            if (!startscreen.naturalWidth) {
                startscreen.addEventListener("load", e => {
                    this.wrappit(e.currentTarget as HTMLImageElement, elem, wrapper);
                });
            } else {
                this.wrappit(startscreen, elem, wrapper);
            }
        }
    }
    wrappit(target:HTMLImageElement, elem:HTMLElement, wrapper:HTMLElement) {
        let scale = 1;
        if (target.naturalWidth > elem.offsetWidth && target.naturalHeight > elem.offsetHeight) {
            if (target.naturalWidth > target.naturalHeight) {
                scale = elem.offsetWidth / target.naturalWidth;
            } else {
                scale = elem.offsetHeight / target.naturalHeight;
            }
        } else if (target.naturalWidth > elem.offsetWidth) {
            scale = elem.offsetWidth / target.naturalWidth;
        } else {
            scale = elem.offsetHeight / target.naturalHeight;
        }



        if (scale > 1) scale = 1;
        wrapper.style.transform = `scale(${scale})`;
        wrapper.style.left = Math.floor((Number(elem.offsetWidth) / 2) - (Number(target.naturalWidth * scale) / 2)) + "px";
        wrapper.style.top = Math.floor((Number(elem.offsetHeight) / 2) - (Number(target.naturalHeight * scale) / 2)) + "px";
        let event = new CustomEvent(EHELP_EVENTS.EHELP_IMG_WRAP_RESIZED, {
            bubbles: true,
            detail: {
                scale: scale
            }
        });
        this.element.dispatchEvent(event);
    }    
    simpleNavigate(element:HTMLElement | undefined = undefined) {
        if (!element) {
            element = this.element;
        }
        if (!element) return;
        element.querySelectorAll("[data-navigate-to]").forEach(elem => {
            elem.addEventListener("click", (e:MouseEvent) => {
                if (!e.shiftKey && !e.ctrlKey) {
                    e.preventDefault();
                    this.navigateTo(e);
                }
            })
        });
    }    
    navigateTo(e:MouseEvent) {
        let uri = (e.currentTarget as HTMLElement).dataset.navigateTo;
        this.parent.loading.start();
        this.parent.router.navigateTo(uri);
    }
}