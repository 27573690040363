var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      <li>\r\n        <a href=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"root") || (depth0 != null ? lookupProperty(depth0,"root") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"root","hash":{},"data":data,"loc":{"start":{"line":7,"column":17},"end":{"line":7,"column":25}}}) : helper)))
    + alias4((lookupProperty(helpers,"route")||(depth0 && lookupProperty(depth0,"route"))||alias2).call(alias1,"devices",{"name":"route","hash":{},"data":data,"loc":{"start":{"line":7,"column":25},"end":{"line":7,"column":44}}}))
    + "/"
    + alias4(container.lambda(((stack1 = (depths[1] != null ? lookupProperty(depths[1],"device") : depths[1])) != null ? lookupProperty(stack1,"path") : stack1), depth0))
    + "/"
    + alias4(((helper = (helper = lookupProperty(helpers,"topicPath") || (depth0 != null ? lookupProperty(depth0,"topicPath") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"topicPath","hash":{},"data":data,"loc":{"start":{"line":7,"column":64},"end":{"line":7,"column":77}}}) : helper)))
    + "\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"topicName") || (depth0 != null ? lookupProperty(depth0,"topicName") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"topicName","hash":{},"data":data,"loc":{"start":{"line":7,"column":79},"end":{"line":7,"column":92}}}) : helper)))
    + "</a>\r\n        <i class=\"bi bi-chevron-right\"></i>\r\n      </li>\r\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"widget device-tutorial-list\">\r\n  <h2>"
    + container.escapeExpression((lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||container.hooks.helperMissing).call(alias1,"pages/device/tutorial-list/title",{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":2,"column":6},"end":{"line":2,"column":54}}}))
    + "</h2>\r\n\r\n  <ul>\r\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"topics") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":4},"end":{"line":10,"column":13}}})) != null ? stack1 : "")
    + "  </ul>\r\n</div>";
},"useData":true,"useDepths":true});