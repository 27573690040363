import { App } from "..";
import { Pages } from "../../../pages/Pages";
import { CategoryTS } from "../../../types/Category";
import { DeviceTS } from "../../../types/Device";
import { UsecaseTS } from "../../../types/Usecase";
import { SimpleNavigate } from "../../../widgets/SimpleNavigate";
import { FAQs } from "../widgets/FAQs";
declare global {
  var BuscadorWidget: any;
}

export class Home extends Pages {
  parent: App;
  async init() {
    let device_list: DeviceTS[] = await this.call("devices");
    let categories: CategoryTS[] = await this.call("categories");
    let faq = device_list.find(item=>{
      return item.path == 'faq';
    })
    let topics = await this.call(`topics/device/${faq.id}/with-faq`);
    let faqs_list: UsecaseTS[] = topics["faq"];
    let devices_ordered: DeviceTS[] = [];


    devices_ordered = categories.map((item) => {
      return device_list.find((dev) => {
        return dev.path == item.path;
      });
    });

    this.render(
      this.template({
        devices: this.parent.settings.templates.widgets.devices({
          devices: devices_ordered,
        }),
        trilhas: this.parent.settings.templates.widgets.trails(),
        faqs: this.parent.settings.templates.widgets.faqs({
          deviceType: faq.types[0],
          faq: faq,
          faqs: faqs_list,
        }),
      }),
      async () => {
        this.parent.dataLayer({
          pageTitle: this.translate("pages/home/pageTitle"),
          pageType: this.translate("pages/home/pageType"),
          hourOfSession: this.parent.user.sesseionStarted.toLocaleString("pt-BR", {
            hour: "2-digit",
            hour12: false,
            minute: "2-digit",
          }),
        });

        const faqs = this.registerWidget(new FAQs("faqs"), "faqs");
        const buscador = new BuscadorWidget(".buscador-qelp");
        await buscador.init({
          api: this.parent.settings.buscador.api[this.parent.environment],
          label: this.parent.settings.buscador.label,
          placeholder: this.parent.settings.buscador.placeholder,
          close: this.parent.settings.buscador.close,
          back: this.parent.settings.buscador.back,
          force_modal: this.parent.settings.buscador.force_modal,
          result_list: this.parent.settings.buscador.result_list,
          list: this.parent.settings.buscador.list,
          max: this.parent.settings.buscador.max,
        });
        const search_button = this.element.querySelector(
          ".qelp-search-bar button"
        );

        search_button.addEventListener("click", (e: Event) => {
          buscador.appModal();
        });
      }
    );
  }
}
