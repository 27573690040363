var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, alias4="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"text-center\">\r\n  <div class=\"welcome-header\">\r\n    <div>\r\n      <h1>"
    + alias3((lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"pages/home/page-title",{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":4,"column":10},"end":{"line":4,"column":47}}}))
    + "</h1>\r\n      <div class=\"horizontal-decorator\"></div>\r\n      <p class=\"lead\">\r\n        "
    + alias3((lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"pages/home/page-description",{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":7,"column":8},"end":{"line":7,"column":51}}}))
    + "\r\n      </p>\r\n    </div>\r\n\r\n\r\n    <div class=\"buscador-qelp\">\r\n    </div>\r\n  </div>\r\n  <div class=\"main-content\">\r\n    <div class=\"widget devices full-width\">\r\n      "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"devices") || (depth0 != null ? lookupProperty(depth0,"devices") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"devices","hash":{},"data":data,"loc":{"start":{"line":17,"column":6},"end":{"line":17,"column":19}}}) : helper))) != null ? stack1 : "")
    + "\r\n    </div>\r\n    <div class=\"aside-content\">\r\n      "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"trilhas") || (depth0 != null ? lookupProperty(depth0,"trilhas") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"trilhas","hash":{},"data":data,"loc":{"start":{"line":20,"column":6},"end":{"line":20,"column":19}}}) : helper))) != null ? stack1 : "")
    + "\r\n      "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"faqs") || (depth0 != null ? lookupProperty(depth0,"faqs") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"faqs","hash":{},"data":data,"loc":{"start":{"line":21,"column":6},"end":{"line":21,"column":16}}}) : helper))) != null ? stack1 : "")
    + "\r\n    </div>\r\n  </div>\r\n</div>";
},"useData":true});