export class Router {
  root: string;
  mode: string;
  page404: Function;
  routes: Routes[];
  transfer: any | undefined;
  listening: boolean;
  constructor(data: RouteData) {
    this.root = data.root;
    this.mode = data.mode;
    this.page404 = data.page404;
  }
  addUriListener() {
    this.run();
    this.listening = true;
    window.onhashchange = (e) => {
      this.run();
    };
  }
  add(routes: Routes[]) {
    if (!this.routes) {
      this.routes = routes;
    } else {
      this.routes.concat(routes);
    }
  }
  run() {
    if (!this.routes || this.routes.length == 0) return;
    let resolve = false;
    let location =
      this.mode == "history"
        ? window.location.pathname
        : window.location.hash.replace(/\#\\|\#/, "");

    for (var i = 0; i < this.routes.length; i++) {
      const test = this.routes[i].test;
      let params: string[] = test.split("/");

      params = params.map((item) => {
        return item === ":any" ? "([^/]*)" : item;
      });

      const regex = new RegExp("^" + params.join("/") + "$");

      if (regex.test(location.replace(/^\//g, ""))) {
        console.warn("Navigating to: ", regex, location);
        try {
          const sections = test.split("/");
          const segments = location.replace(/^\//g, "").split("/");
          let get: string[] = [];

          for (var j = 0; j < sections.length; j++) {
            if (sections[j] == ":any") {
              get.push(segments[j]);
            }
          }

          this.routes[i].resolve(get);
          resolve = true;
        } catch (error) {
          console.log(error);
        }
        break;
      }
    }
    if (!resolve) {
      this.page404();
    }
  }
  navigateTo(route: string, transfer: any | null = null) {
    if (transfer) this.transfer = transfer;
    if (this.mode == "hash") {
      window.location.hash = route;
    } else {
      this.run();
    }
  }
}
export interface Routes {
  test: string;
  resolve: Function;
}
export interface RouteData {
  root: string;
  mode: string;
  page404: Function;
}
