import { sha256 } from "js-sha256";
import { UsecaseTS } from "../types/Usecase";
import { Widgets } from "./Widgets";

export class PRS extends Widgets {
  buttons: NodeListOf<HTMLButtonElement>;
  isituseful: HTMLDivElement;
  evaluation: HTMLDivElement;
  close_evaluation: HTMLDivElement;
  send_evaluation: HTMLDivElement;
  usecase: UsecaseTS;

  init() {
    this.buttons = this.element.querySelectorAll(".isituseful button");
    this.isituseful = this.element.querySelector(".isituseful");
    this.evaluation = this.element.querySelector(".evaluation");
    this.close_evaluation = this.element.querySelector(
      ".evaluation .close-btn"
    );
    this.send_evaluation = this.element.querySelector(".evaluation .btn-send");

    this.close_evaluation.addEventListener("click", (e) => {
      this.closeEvaluation();
    });
    this.send_evaluation.addEventListener("click", (e) => {
      const comment = this.evaluation.querySelector("textarea").value.trim();
      const data = new FormData();
      data.append("topicId", this.usecase.id.toString());
      data.append("userhash", this.parent.user.data.id);
      data.append("comment", comment);

      this.parent.post("comments", data);
      this.parent.dataLayer({
        event: 'comment',
        whyNotUseful: comment
      });
      this.closeEvaluation();
    });
    this.buttons.forEach((item) => {
      item.addEventListener("click", (e) => {
        this.buttonClicked(e);
      });
    });
  }
  closeEvaluation() {
    this.evaluation.classList.add("hide");
    this.isituseful.classList.remove("hide");
    this.isituseful.classList.add("thankyou");
    this.remove();
  }
  buttonClicked(e: Event) {
    const target = e.currentTarget as HTMLButtonElement;
    let rate: number;
    if (target.classList.contains("yes")) {
      this.isituseful.classList.add("thankyou");
      this.remove();
      rate = 5;
    }
    if (target.classList.contains("no")) {
      this.isituseful.classList.add("hide");
      this.evaluation.classList.remove("hide");
      rate = 1;
    }
    const data = new FormData();

    data.append("topicId", this.usecase.id.toString());
    data.append("userhash", this.parent.user.data.id);
    data.append("rating", rate.toString());

    this.parent.user.vote({
      hashkey: sha256(this.parent.currentLocation()),
      rating: rate,
    });

    this.parent.dataLayer({
      topicRating: rate,
      problemResolutionScore: rate == 5 ? "yes" : "no",
    });

    this.parent.post("ratings", data, {
      "Content-Type": "multipart/form-data",
    });
  }
  remove() {
    setTimeout(() => {
      this.element.remove();
    }, 3000);
  }
}
