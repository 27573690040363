import { Pages } from "./Pages";
import { BreadcrumbTS } from "../types/Breadcrumb";
import { DeviceTS } from "../types/Device";
import { TopicTS } from "../types/Topic";
import { UsecaseTS } from "../types/Usecase";
import { DeviceImage } from "../widgets/DeviceImage";
import { Qlickr } from "../widgets/Qlickr";
import { BackToTop } from "../widgets/BackToTop";
import { PRS } from "../widgets/PRS";
import { SimpleHeader } from "../projects/ultragaz/widgets/SimpleHeader";
import { CategoryTS } from "../types/Category";

declare global {
  var _ga_laststep: number;
}

export class Usecase extends Pages {
  device_path: string;
  topic_path: string;
  laststep: number;
  constructor(params: string[]) {
    super();
    window._ga_laststep = 0;
    this.device_path = params[0];
    this.topic_path = params[1];
  }

  async init() {
    const device: DeviceTS = await this.call(`devices/${this.device_path}`);
    const topics: TopicTS[] = await this.call(`topics/device/${device.id}`);
    let topic = topics.find((item) => {
      return item.topicPath == this.topic_path;
    });
    const category: CategoryTS = await this.call(
      `categories/${topic.categoryId}`
    );
    let usecase: UsecaseTS = await this.call(`topics/${topic.id}`);
    let related = topics.filter((item) => {
      return item.id != usecase.id;
    });
    related = related.sort(() => Math.random() - 0.5).slice(0, 10);

    const breadcrumb: BreadcrumbTS[] = [
      {
        label: this.translate("breadcrumbs/home"),
        path: "",
      },
      {
        label: device.name,
        path: this.parent.settings.routeNames.devices + "/" + device.path,
      },
    ];

    const simple_header: string =
      this.parent.settings.templates.widgets.simple_header({
        breadcrumb: breadcrumb,
      });
    const prs: string = this.parent.settings.templates.widgets.prs({});
    const back_to_top: string =
      this.parent.settings.templates.widgets.back_to_top({});
    const related_topics: string =
      related.length > 0
        ? this.parent.settings.templates.widgets.related_topics({
            device: device,
            topics: related,
          })
        : null;
    this.render(
      this.template({
        simple_header: simple_header,
        device: device,
        topic: topic,
        prs: prs,
        back_to_top: back_to_top,
        related_topics: related_topics,
        usecase: usecase,
      }),
      async () => {
        for (let i = 0; i < usecase.steps.length; i++) {
          let step = usecase.steps[i];
          let image_selector = "usecase-step-image-" + i;
          let step_selector = ".usecase-step.step-" + i;
          if (step.animation) {
            this.registerWidget(
              new Qlickr(step_selector, image_selector),
              image_selector
            );
          }
          this.registerWidget(new DeviceImage(image_selector), image_selector);
        }
        this.registerWidget(new BackToTop("back-to-top"), "back-to-top");
        const prs = this.registerWidget(new PRS("prs"), "prs") as PRS;
        prs.usecase = usecase;
        const simple_header = this.registerWidget(
          new SimpleHeader("simple-header"),
          "simple-header"
        );

        try {
          this.parent.dataLayer({
            pageTitle:
              this.translate("pages/usecase/pageTitle") +
              ` - ${device.name} - ${topic.topicName}`,
            pageType: this.translate("pages/usecase/pageType"),
            contentClass: category.name,
            contentTopic: topic.topicName,
            contentType: (device.types && device.types.length && device.types[0].name) ? device.types[0].name : '',
            contentVariety: "",
            deviceOS: (device.os.name && device.os.name !== 'N/A') ? device.name : '',
            numberOfSteps: usecase.steps.length,
          });
        } catch (e) {
          console.log(e);
        }

        this.scrollHandle();
      }
    );
  }
  scrollHandle() {
    let _this = this;
    let steps = document.querySelectorAll(".usecase-step");
    for (let i = 0; i < steps.length; i++) {
      let step = steps[i];
      let bound = step.getBoundingClientRect();

      if (bound.top > 0 && bound.top < window.innerHeight) {
        if (!step.classList.contains("focus")) {
          updateGTM(i, steps);
          steps.forEach((elemn) => {
            elemn.classList.remove("focus");
          });
          step.classList.add("focus");
        }
        break;
      }
    }
    function updateGTM(num: number, steps: NodeListOf<Element>) {
      num++;
      if (num > window._ga_laststep) {
        window._ga_laststep = num;
        try {
          _this.parent.dataLayer({
            event: "completion",
            lastStepViewed: num,
            completionRate: num / steps.length,
          });
        } catch (e) {
          console.log(e);
        }
      }
    }
    if (steps.length > 0) {
      setTimeout(() => {
        _this.scrollHandle();
      }, 100);
    }
  }
}
