import { AxiosResponse } from "axios";
import { App } from "..";
import { eHelp } from "../../../eHelp";
import { Pages } from "../../../pages/Pages";
import { BreadcrumbTS } from "../../../types/Breadcrumb";
import { TrailsTS } from "../types/Trails";
import { ProfileSelector, PROFILE_SELECTED } from "../widgets/ProfileSelector";
import { SideMenu, SIDE_MENU_ITEM_CLICKED } from "../widgets/SideMenu";
import { SimpleHeader } from "../widgets/SimpleHeader";
import { TrailList } from "../widgets/TrailList";

interface Videos {
  data: {
    last_page: number;
    total_lenght: number;
    data: Video[];
  };
  status: number;
  statusText: string;
}
interface Video {
  video_cod: string;
  video_titulo: string;
  video_id: string;
  video_duracao: number;
  video_thumbnail: string;
  video_url: string;
  video_data_publicacao: string;
  video_contador_visualizacoes: number;
  video_inserted_at: string;
  publico: [
    {
      publico_cod: string;
      publico_titulo: string;
      publico_visualizacoes_contador: number;
    }
  ];
  tag: [
    {
      tag_cod: string;
      tag_titulo: string;
    }
  ];
}
interface UltragazUser {
  user: {
    user_cod: "string";
    user_email: "string";
    user_inserted_at: "string";
  };
  resale: [
    {
      revenda_cod: "string";
    }
  ];
}
export class Trails extends Pages {
  parent: App;
  user_videos: Videos;
  videos: Video[] = [];
  video_pages: Videos[] = [];
  user: UltragazUser;
  trailList: TrailList;
  async init() {
    const trails: TrailsTS = require("../assets/json/trails/trails.json");
    try {
      await this.getVideos();
      this.user_videos = await this.parent.connect(
        this.parent.settings.extra.api_ultragaz[this.parent.environment].api +
          `usuarios/${this.parent.userCode}/videos`,
        {
          method: "GET",
          headers: {
            client_id: this.parent.client_id,
            access_token: this.parent.access_token,
          },
        }
      );
    } catch (error) {
      console.warn(error);
    }

    trails.content.sort((a, b) => {
      return a.order - b.order;
    });

    trails.content.map((item, index) => {
      item.youtube_video = this.videos.find((video) => {
        return video.video_id == item.video_id;
      });
      item.publico_cod = item.youtube_video?.publico[0].publico_cod
    });
    try {      
      trails.content.map((item) => {
        item.watched = this.user_videos.data.data.find((video) => {
          return video.video_cod == item.youtube_video?.video_cod;
        })
          ? true
          : false;
      });
    } catch (error) {
      console.warn("Unable to map watched videos", this.user_videos);
      
    }

    // console.log(trails);
    // console.log(this.user_videos);

    const breadcrumb: BreadcrumbTS[] = [
      {
        label: "Home",
        path: "",
      },
    ];
    const simple_header: string =
      this.parent.settings.templates.widgets.simple_header({
        breadcrumb: breadcrumb,
      });
    const profile_selector =
      this.parent.settings.templates.widgets.profile_selector({
        profiles: trails.profiles,
      });
    const side_menu = this.parent.settings.templates.widgets.side_menu({
      title: this.translate("widgets/aside-content/title"),
      list: trails.trails,
    });
    const trail_list = this.parent.settings.templates.widgets.trail_list({
      trails: trails,
    });
    this.render(
      this.template({
        simple_header: simple_header,
        side_menu: side_menu,
        profile_selector: profile_selector,
        trail_list: trail_list,
      }),
      async () => {
        this.parent.dataLayer({
          pageTitle: this.translate("pages/trilhas/pageTitle"),
          pageType: this.translate("pages/trilhas/pageType"),
          userIdentification: this.parent.userCode,
        })


        const simple_header = this.registerWidget(
          new SimpleHeader("simple-header"),
          "simple-header"
        );
        const profile_selector = this.registerWidget(
          new ProfileSelector("profile-selector"),
          "profile-selector"
        );
        const trail_list = this.registerWidget(
          new TrailList("trail-list"),
          "trail-list"
        ) as TrailList;

        const side_menu = this.registerWidget(
          new SideMenu("side-menu"),
          "side-menu"
        );

        side_menu.element.addEventListener(
          SIDE_MENU_ITEM_CLICKED,
          (e: CustomEvent) => {
            const element = trail_list.element.querySelector(
              `.trail[data-id="${e.detail.id}"]`
            );
            element.scrollIntoView({
              behavior: "smooth",
              block: "center",
              inline: "nearest",
            });
          }
        );

        profile_selector.element.addEventListener(
          PROFILE_SELECTED,
          (e: CustomEvent) => {
            const button = e.target as HTMLButtonElement;
            const profile_id = e.detail.profile_id;
            const active = e.detail.active;
            const list = trail_list as TrailList;
            list.content.forEach((item) => {
              if (!item.dataset.profiles.match(profile_id) && active) {
                item.classList.add("unselected");
              } else {
                item.classList.remove("unselected");
              }
            });
          }
        );
        
        this.trailList = trail_list;
      }
    );
  }
  /* async getVideos(): Promise<any | null> {
    const videos = await this.parent.connect(
      this.parent.settings.extra.api_ultragaz[this.parent.environment].api +
        "videos",
      {
        method: "GET",
        headers: {
          client_id: this.parent.client_id,
          access_token: this.parent.access_token,
        },
        data: {
          page: this.video_pages.length + 1,
        },
      }
    );
    this.video_pages.push(videos.data);
    this.videos = this.videos.concat(videos.data.data);
    if (this.video_pages.length < videos.data.last_page) {
      return this.getVideos();
    } else {
      return null;
    }
  } */

    async getVideos(): Promise<any | null> {
      let currentPage = 1;
      let lastPage = 1;
    
      while (currentPage <= lastPage) {
        const response = await this.parent.connect(
          `${this.parent.settings.extra.api_ultragaz[this.parent.environment].api}videos?size=130`,
          {
            method: "GET",
            headers: {
              client_id: this.parent.client_id,
              access_token: this.parent.access_token,
            },
          }
        );
    
        const videos = response.data;
    
        if (!videos) {
          throw new Error("Failed to fetch videos");
        }
    
        // Append the current page of videos to the list
        this.video_pages.push(videos);
        this.videos = this.videos.concat(videos.data);
    
        // Update pagination info
        lastPage = videos.last_page;
        currentPage++;
      }
    }
}