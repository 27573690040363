
import { App } from "..";
import { Pages } from "../../../pages/Pages";
import { BreadcrumbTS } from "../../../types/Breadcrumb";
import { CategoryTS } from "../../../types/Category";
import { DeviceTS } from "../../../types/Device";
import { SimpleHeader } from "../widgets/SimpleHeader";

export class Device extends Pages {
  device: string;
  parent: App;
  constructor(device: string) {
    super();
    this.device = device;
  }
  async init() {
    const devices: DeviceTS[] = await this.parent.call("devices");
    const categories:CategoryTS[] = await this.parent.call("categories");
    const device = devices.find((item) => {
      return item.path == this.device;
    });
    const category:CategoryTS = categories.find(item=>{
      return item.path == device.path;
    })    
    const topics = await this.parent.call("topics/device/" + device.id);

    const breadcrumb: BreadcrumbTS[] = [
      {
        label: "Home",
        path: "",
      },
    ];

    const simple_header: string =
      this.parent.settings.templates.widgets.simple_header({
        breadcrumb: breadcrumb,
        css_classes: "full-width",
      });
    const tutorial_list: string =
      this.parent.settings.templates.widgets.device_tutorial_list({
        device: device,
        topics: topics,
      });
    this.render(
      this.template({
        device: device,
        category: category,
        simple_header: simple_header,
        tutorial_list: tutorial_list,
      }),
      async () => {
        this.parent.dataLayer({
          contentTopic: "",
          pageTitle: "Temas - " + device.name,
          contentClass: device.name,
          pageType: this.translate("pages/device/pageType"),
          contentType: (device.types && device.types.length && device.types[0].name) ? device.types[0].name : "",
          userIdentification: this.parent.userCode,
        });
        const simple_header = this.registerWidget(new SimpleHeader("simple-header"), "simple-header");
      }
    );
  }
}
