import { Router, Routes } from "./helpers/Router";
import axios, {
  AxiosBasicCredentials,
  AxiosInstance,
  AxiosStatic,
  Method,
} from "axios";

var Handlebars = require("handlebars/runtime");

import { Widgets } from "./widgets/Widgets";
import { Pages } from "./pages/Pages";
import { Home } from "./pages/Home";
import { Categories } from "./pages/Categories";

import "./scss/main.scss";
import { Category } from "./pages/Category";

require("./helpers/handlebars-helpers.js");
import "bootstrap";
import { Device } from "./projects/ultragaz/pages/Device";
import { User } from "./library/User";
import { DataLayer } from "./types/DataLayer";

declare global {
  var eHelpWidget: eHelp;
  var dataLayer: any[];
  var eHelpDataLayer: any[];
}

export class eHelp {
  element: HTMLElement;
  selector: string;
  api: AxiosInstance;
  router: Router;
  loader: any;
  spinning: HTMLElement;
  build_path: string;
  user: User = new User();
  css_list: string[] = [];
  script_list: { src: string; id: string }[] = [];
  environment: string;

  widgets: WidgetList = {};
  pages: PageList = {};
  settings: Settings;
  customRoutes: RouterData[];
  debug = {
    dataLayer: () => {
      if (window.eHelpDataLayer) {
        console.warn("eHelpDataLayer");

        let prompt: Prompt = {};
        window.eHelpDataLayer.forEach((item) => {
          for (let prop in item) {
            if (!prop.match(/gtm|event/)) {
              prompt[prop] = item[prop];
            }
          }
        });
        // console.log(prompt);
        return prompt;
      } else {
        console.log("No eHelpDataLayer has been found");
      }
    },
  };
  loading = {
    start: () => {
      this.element.classList.add("loading");
      this.spinning = document.createElement("div");
      this.spinning.classList.add("ehelp-loading-spinner");
      this.spinning.innerHTML = this.settings.loader;
      this.element.append(this.spinning);
    },
    stop: () => {
      this.element.classList.remove("loading");
      this.spinning.remove();
    },
  };

  constructor(element: string) {
    window.eHelpWidget = this;
    this.selector = element;
  }
  ready(callback: Function | null = null) {
    this.init();
    if (callback) callback();
  }
  loadCSS(src: string) {
    return new Promise((resolve, reject) => {
      const l = document.createElement("link");
      l.rel = "stylesheet";
      l.href = src;
      const fs = document.getElementsByTagName("link")[0];
      fs.parentNode.insertBefore(l, fs);
    });
  }
  async loadScript(src: string, id: string) {
    return new Promise((resolve, reject) => {
      let script = document.createElement("script");
      script.type = "text/javascript";
      script.src = src;
      script.id = id;
      script.addEventListener("load", () => resolve(script), false);
      script.addEventListener("error", () => reject(script), false);
      const fs = document.getElementsByTagName("script")[0];
      fs.parentNode.insertBefore(script, fs);
    });
  }
  registerPage(page: Pages, name: string) {
    for (const [key, value] of Object.entries(this.pages)) {
      if (key !== name) value.unload();
    }
    page.name = name;
    page.parent = this;
    page.element.classList.add(name);
    page.template = this.settings.templates.pages[name];
    if (!page.template) {
      console.warn("Page has no template configured.", page);
    }
    page.init();
    this.pages[name] = page;
    return page;
  }
  registerWidget(widget: Widgets, name: string) {
    widget.parent = this;
    widget.init();
    this.widgets[name] = widget;
    return widget;
  }
  dataLayer(data: DataLayer) {
    window.eHelpDataLayer = eHelpDataLayer ? eHelpDataLayer : [];
    try {
      window.eHelpDataLayer.push(data);
    } catch (error) {
      console.warn(error);
    }
    window.dataLayer = window.eHelpDataLayer;
  }
  init() {
    this.element = document.querySelector(this.selector);
    this.element.classList.add("ehelp-widget");
    this.settings.api.forEach((item: any) => {
      if (item.test.test(window.location.host + window.location.pathname)) {
        this.api = axios.create({
          baseURL: item.set,
          timeout: 10000,
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
        });
      }
    });
    this.initRouter(this.settings);
    this.router.navigateTo(this.currentLocation());
  }
  async call(url: string): Promise<any[] | any> {
    return await (
      await this.api.get(`${url}`)
    ).data.data;
  }
  async post(url: string, data: any, headers?: any): Promise<any[] | any> {
    return await (
      await this.api.post(`${url}`, data, { headers: headers })
    ).data.data;
  }
  async connect(
    url: string,
    params: {
      method: Method;
      auth?: AxiosBasicCredentials;
      data?: {};
      headers?: any;
    }
  ): Promise<any> {
    return await axios.request({
      url: url,
      method: params.method,
      auth: params.auth,
      data: params.data,
      headers: params.headers,
    });
  }
  config(settings: Settings) {
    this.settings = settings;
    const event = new CustomEvent(EHELP_EVENTS.EHELP_SETUP_FINISHED, {
      bubbles: true,
      detail: {
        config: this.settings,
      },
    });
    document.dispatchEvent(event);
  }

  initRouter(settings: any) {
    try {
      for (var i = 0; i < settings.root.length; i++) {
        const test = settings.root[i].test;
        console.log(test);
        if (test.test(window.location.host + window.location.pathname)) {
          this.build_path = settings.root[i].build_path;
          this.environment = settings.root[i].environment || Environment.DEV;
          this.router = new Router({
            mode: settings.root[i].mode ? settings.root[i].mode : "history",
            root: settings.root[i].set,
            page404: function () {},
          });
          break;
        }
      }

      if (!this.router) {
        this.build_path = settings.rootFallback.build_path;
        this.environment = settings.rootFallback.environment || Environment.DEV;
        this.router = new Router({
          mode: settings.rootFallback.mode ? settings.rootFallback.mode : "history",
          root: settings.rootFallback.set,
          page404: function () {},
        });

        this.api = axios.create({
          baseURL: settings.rootFallback.api,
          timeout: 10000,
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
        });
        
      }

      this.addRoutes();
      this.router.addUriListener();
    } catch (error) {
      console.error("Cannot determine the root for the application!", error);
    }
  }
  translate(path_str: string) {
    try {
      let locale: object = this.settings.localization[this.settings.locale];
      let path = "['" + path_str.replace(/\//g, "']['") + "']";
      return eval(`locale${path}`);
    } catch (e) {
      path_str;
    }
  }
  currentLocation() {
    let currentLocation;
    if (this.router.mode == "hash") {
      currentLocation = window.location.hash.replace(/\#\\|\#/, "");
    } else {
      currentLocation = window.location.toString().split(this.router.root)[1];
    }
    // console.log(currentLocation);
    return currentLocation;
  }
  addRoutes() {
    console.log("Adding default routes");
    this.addDefaultRoutes();
  }
  private addDefaultRoutes() {
    this.router.add([
      {
        test: "",
        resolve: () => {
          this.loading.start();
          const page = this.registerPage(new Home(), "home");
        },
      },
      {
        test: this.settings.routeNames.devices + "/:any",
        resolve: (params: string[]) => {
          this.loading.start();
          const page = this.registerPage(new Device(params[0]), "device");
        },
      },
      {
        test: this.settings.routeNames.categories,
        resolve: (params: string[]) => {
          this.loading.start();
          const page = this.registerPage(new Categories(), "categories");
        },
      },
      {
        test: this.settings.routeNames.categories + "/:any",
        resolve: (params: string[]) => {
          this.loading.start();
          const page = this.registerPage(new Category(), "category");
        },
      },
    ]);
  }
  error404() {}
}

export interface Settings {
  locale: string;
  localization: Localization;
  assets: string;
  rootFallback: {
    set: string,
    mode: string,
    environment: string,
    build_path: string,
    api: string,
  };
  root: SetTestMode[];
  api: SetTestMode[];
  loader: string;
  templates: {
    widgets?: HandlebarsTemplate;
    pages?: HandlebarsTemplate;
  };
  routeNames?: {
    home?: string;
    application?: string;
    devices?: string;
    device?: string;
    categories?: string;
    type?: string;
    group?: string;
    manufacturers?: string;
  };
  buscador?: {
    api: APIBuscador;
    label: string;
    placeholder: string;
    close: string;
    back: string;
    force_modal?: boolean;
    result_list: {
      head: string;
      "no-results": string;
    };
    list: {
      history: {
        label: string;
      };
      manufacturers?: {
        label: string;
        notfound: string;
      };
      devices?: {
        label: string;
        notfound: string;
      };
      topics?: {
        label: string;
        notfound: string;
      };
    };
    max: {
      topics?: number;
      devices?: number;
      manufacturers?: number;
      history?: number;
      width?: number;
    };
  };
  extra?: any;
}
export interface APIBuscador {
  [key: string]: string;
}
export const Environment = {
  DEV: "development",
  ACCEPT: "accept",
  LIVE: "live",
};
interface Prompt {
  [key: string]: Pages;
}
export interface PageList {
  [key: string]: Pages;
}
export interface WidgetList {
  [key: string]: Widgets;
}
export interface Localization {
  [key: string]: object[];
}
export interface HandlebarsTemplate {
  [key: string]: Function;
}
interface SetTestMode {
  test: RegExp;
  set: string;
  mode?: string;
  build_path?: string;
  environment?: string;
}
export interface RouterData {
  test: string;
  resolve: Function;
}
export const EHELP_EVENTS = {
  EHELP_PAGE_RENDERED: "EHELP_PAGE_RENDERED",
  EHELP_SCRIPT_LOADED: "EHELP_SCRIPT_LOADED",
  EHELP_IMG_WRAP_RESIZED: "EHELP_IMG_WRAP_RESIZED",
  EHELP_SETUP_FINISHED: "EHELP_SETUP_FINISHED",
  EHELP_READY: "EHELP_READY",
};
