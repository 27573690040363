import { Pages } from "./Pages";

export class Home extends Pages {
  async init() {
    let manufacturers = await this.parent.call("devices");
    this.render(
      this.template({
        manufacturers: manufacturers,
      }),
      null
    );
  }
}
