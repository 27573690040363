import { Widgets } from "../../../widgets/Widgets";

export class ProfileSelector extends Widgets {
    buttons: NodeListOf<HTMLButtonElement>;
    init() {
        this.buttons = this.element.querySelectorAll('button');
        this.buttons.forEach(item => {
            item.addEventListener('click', e => {
                this.selectProfile(e);
            });
        });
    }
    selectProfile(e:Event) {
        const button = e.currentTarget as HTMLButtonElement;
        button.blur();
        this.buttons.forEach(item => {
            if (item!==button) {
                item.classList.remove('active');
            } else {
                item.classList.toggle("active");
            }
        });
        const profile_id = button.dataset.profileId;

        let event = new CustomEvent(PROFILE_SELECTED, {
            bubbles: true,
            detail: {
                profile_id: profile_id,
                active: button.classList.contains("active")
            }
        });
        this.element.dispatchEvent(event);        
    }
}

export const PROFILE_SELECTED = "PROFILE_SELECTED";