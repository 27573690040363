import { App } from "..";
import { Pages } from "../../../pages/Pages";
import { TrailsTS } from "../types/Trails";
declare global {
  var YT: {
    Player: any;
    PlayerState: any;
  };
}

interface PlayerEvent {
  data: any;
  target: {
    playVideo: Function;
  };
}
interface VideoData {
  video_cod: string;
  video_titulo: string;
  video_id: string;
  video_duracao: number;
  video_thumbnail: string;
  video_url: string;
  video_data_publicacao: string;
  video_contador_visualizacoes: number;
  video_inserted_at: string;
  publico: [
    {
      publico_cod: string;
      publico_titulo: string;
      publico_visualizacoes_contador: number;
    }
  ];
  tag: [
    {
      tag_cod: string;
      tag_titulo: string;
    },
    {
      tag_cod: string;
      tag_titulo: string;
    },
    {
      tag_cod: string;
      tag_titulo: string;
    }
  ];
}

export class VideoPlayer extends Pages {
  parent: App;
  player: HTMLElement;
  modal: HTMLElement;
  currentVideo: HTMLDivElement;
  watched: boolean = false;
  video_cod: string;
  ytplayer: {
    getPlayerState: Function;
    playerInfo: {
      currentTime: number;
    };
  };
  constructor(video_cod: string) {
    super();
    this.video_cod = video_cod;
  }

  async init() {
    const watched: any = await this.parent.connect(
      this.parent.settings.extra.api_ultragaz[this.parent.environment].api +
      `usuarios/${this.parent.userCode}/videos`,
      {
        method: "GET",
        headers: {
          client_id: this.parent.client_id,
          access_token: this.parent.access_token,
          "Content-Type": "application/json",
        },
      }
    );
    const response: any = await this.parent.connect(
      this.parent.settings.extra.api_ultragaz[this.parent.environment].api +
      `videos/${this.video_cod}`,
      {
        method: "GET",
        headers: {
          client_id: this.parent.client_id,
          access_token: this.parent.access_token,
        },
      }
    );
    const videodata = response.data.data as VideoData;

    watched.data.data.forEach((item: VideoData) => {
      if (videodata.video_cod == item.video_cod) {
        this.watched = true;
      }
    });

    this.render("", async () => {
      this.openContent(videodata);
    });
  }
  async openContent(target: VideoData) {
    console.warn("opening video content");
    const trails: TrailsTS = require("../assets/json/trails/trails.json");
    const content = trails.content.find((item) => {
      return item.video_id == target.video_id;
    });
    const trail = trails.trails.find((item) => {
      return item.id == content.trail;
    });
    const attchment = content.anexo;
    const videoId = content.video_id
    const profile = trails.profiles
      .filter((item) => {
        return content.profiles.includes(item.id);
      })
      .map((item) => {
        return item.name;
      });

    this.parent.dataLayer({
      pageTitle: "Trilhas - " + target.video_titulo,
      pageType: "Trilhas - Video",
      contentClass: trail.name,
      videoStep: content.order.toString(),
      videoClicked: target.video_titulo,
      videoCode: target.video_cod,
      userIdentification: this.parent.userCode,
      profile: profile && profile.length ? profile.join(" - ") : "",
    });

    try {
      if (!this.watched) {
        await this.parent.connect(
          this.parent.settings.extra.api_ultragaz[this.parent.environment].api +
          `usuarios/${this.parent.userCode}/videos`,
          {
            method: "PUT",
            headers: {
              client_id: this.parent.client_id,
              access_token: this.parent.access_token,
              "Content-Type": "application/json",
            },
            data: JSON.stringify({
              videoCod: target.video_cod,
            }),
          }
        );
      } else {
        await this.parent.connect(
          this.parent.settings.extra.api_ultragaz[this.parent.environment].api +
          `visualizacoes`,
          {
            method: "PUT",
            headers: {
              client_id: this.parent.client_id,
              access_token: this.parent.access_token,
              "Content-Type": "application/json",
            },
            data: JSON.stringify({
              videoCod: target.video_cod,
              userCod: this.parent.userCode,
              publicoCod: target.publico[0].publico_cod,
            }),
          }
        );
      }
    } catch (error) {
      console.warn(error);
    }

    this.player = document.createElement("div");
    this.player.classList.add("player-wrapper");

    this.player.innerHTML = `<div id='yt-player'></div>`;

    this.modal = document.createElement("div");
    this.modal.classList.add("ehelp-widget-modal", "youtube-player-modal");

    const close_btn = document.createElement("div");
    close_btn.classList.add("close-btn");
    close_btn.innerHTML = this.parent.translate("widgets/trail-list/close-btn");

    this.modal.append(close_btn);

    const open_attach = document.createElement("div");
    open_attach.classList.add("open_attach");
    open_attach.innerHTML = 'Material de apoio';

    const arquivos = document.createElement("div");
    arquivos.classList.add("file_container"); // Corrigido aqui
    arquivos.append(open_attach)
    if (attchment === "1") {
      //this.modal.append(open_attach);
      this.modal.append(arquivos);
      this.downloadAttachment(arquivos, videoId);
    }

    this.modal.append(this.player);
    document.body.classList.add("youtube-player-modal");
    close_btn.addEventListener("click", (e) => {
      this.closeYTPlayer();
      this.parent.router.navigateTo("/trilhas");
    });
    document.body.append(this.modal);
    this.youtubePlayer(target.video_id);
    this.playerStatus();
  }
  youtubePlayer(video_id: string) {
    // https://developers.google.com/youtube/iframe_api_reference

    if (typeof YT == "undefined" || typeof YT.Player == "undefined") {
      setTimeout(() => {
        this.youtubePlayer(video_id);
      }, 1000);
    } else {
      this.ytplayer = new YT.Player("yt-player", {
        height: "360",
        width: "628",
        videoId: video_id,
        playerVars: {
          playsinline: 1,
        },
        events: {
          onReady: this.onPlayerReady,
          onStateChange: (event: PlayerEvent) => {
            this.onPlayerStateChange(event);
          },
        },
      });
    }
  }
  playerStatus() {
    setTimeout(() => {
      const modal = document.querySelector(".youtube-player-modal");
      if (modal) {
        this.updateVideoStatus();
        this.playerStatus();
      }
    }, 10000);
  }

  closeYTPlayer() {
    try {
      this.updateVideoStatus();
    } catch (error) {
      console.warn("failed to updateVideoStatus");
    }
    document.body.classList.remove("youtube-player-modal");

    if (!this.modal) {
      this.modal = document.querySelector("div.youtube-player-modal");
    }
    this.modal.remove();
    this.ytplayer = null;
  }
  onPlayerReady(event: PlayerEvent) {
    event.target.playVideo();
  }

  updateVideoStatus() {
    const lp = this.ytplayer;
    const currentTime = lp.playerInfo.currentTime;

    this.parent.connect(
      this.parent.settings.extra.api_ultragaz[this.parent.environment].api +
      `visualizacoes`,
      {
        method: "POST",
        headers: {
          client_id: this.parent.client_id,
          access_token: this.parent.access_token,
          "Content-Type": "application/json",
        },
        data: JSON.stringify({
          videoCod: this.video_cod,
          userCod: this.parent.userCode,
          tempoAssistido: currentTime,
        }),
      }
    );
  }
  onPlayerStateChange(event: PlayerEvent) {
    this.updateVideoStatus();
  }
  unload() {
    this.closeYTPlayer();
  }
  downloadAttachment(container: HTMLElement, videoId: string) {
    const files = [
      {
        videoid: "2CuDAQ_O5do",
        name: "4.1 - Material de Apoio - Cálculo do Potencial de Vendas",
        path: "http://extranet.cs-sitel.com.br/company/1.xlsx"
      },
      {
        videoid: "aKMGANsQyR4",
        name: "2.1.1 - Material de Apoio_Modelo - Financeira Geral",
        path: "https://cdn.qelplatam.com/ultragaz-dev/files/2.1.1 - Material de Apoio_Modelo - Financeira Geral.xlsx"
      },
      {
        videoid: "ZnDvLxhZ5s4",
        name: "5.1 - Material de Apoio - Despesas da Entrega do Gás no Cliente",
        path: "https://cdn.qelplatam.com/ultragaz-dev/files/5.1 - Material de Apoio - Despesas da Entrega do Gás no Cliente.xlsx"
      },
      {
        videoid: "kB4IyZTpAis",
        name: "1.1 - Material de Apoio - Avaliacao DISC",
        path: "https://cdn.qelplatam.com/ultragaz-dev/files/1.1 - Material de Apoio - Avaliacao DISC.xlsx"
      },
      {
        videoid: "6wYABywSocc",
        name: "3.1 - Material de Apoio 12 itens para uma instalação de botijão de gás segura e de qualidade",
        path: "https://cdn.qelplatam.com/ultragaz-dev/files/3.1 - Material de Apoio 12 itens para uma instalação de botijão de gás segura e de qualidade.pdf"
      },
      {
        videoid: "ZDvvxOzusB8",
        name: "5.1 - Planilha NPS e plano de ações (na mesma Planilha)",
        path: "https://cdn.qelplatam.com/ultragaz-dev/files/5.1 - Planilha NPS e plano de ações (na mesma Planilha).xlsx"
      }
    ];

    files.filter(file => file.videoid === videoId).forEach(file => {
      const fileElement = document.createElement("div");
      fileElement.classList.add("files"); // Corrigido aqui
      const viewLink = document.createElement("a");
      viewLink.href = file.path;
      viewLink.textContent = `Ver ${file.name}`;
      fileElement.appendChild(viewLink);
      container.appendChild(fileElement);
    });
  }
}
