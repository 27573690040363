import "./assets/scss/ultragaz.scss";
import "./assets/scss/defaults.scss";

import { eHelp, Environment } from "../../eHelp";
import { Home } from "./pages/Home";
import { Device } from "./pages/Device";
import { Usecase } from "../../pages/Usecase";
import { Trails } from "./pages/Trails";
import { VideoPlayer } from "./pages/VideoPlayer";

export class App extends eHelp {
  userCode: string;
  client_id: string;
  access_token: string;
  constructor(element: string) {
    super(element);
  }
  async setUser(userCode: string) {
    this.userCode = userCode;
  }
  async login() {
    if (!this.access_token) {
      this.client_id =
        this.settings.extra.api_ultragaz[this.environment].client_id;
      await this.connect(
        this.settings.extra.api_ultragaz[this.environment].auth,
        {
          method: "POST",
          auth: {
            username: this.client_id,
            password: this.settings.extra.api_ultragaz[this.environment].access,
          },
          data: { grant_type: "client_credentials" },
        }
      ).then((response) => {
        this.access_token = response.data.access_token;
      });
    }
  }
  async addRoutes() {
    this.router.add([
      {
        test: "",
        resolve: async () => {
          ehelp_widget.login();
          ehelp_widget.loading.start();
          const page = ehelp_widget.registerPage(new Home(), "home");
          ehelp_widget.dataLayer({
            userIdentification: ehelp_widget.userCode,
          });
        },
      },
      {
        test: ehelp_widget.settings.routeNames.devices + "/:any",
        resolve: (params: string[]) => {
          ehelp_widget.loading.start();
          const page = ehelp_widget.registerPage(
            new Device(params[0]),
            "device"
          );
          ehelp_widget.dataLayer({
            userIdentification: ehelp_widget.userCode,
            hourOfSession: ehelp_widget.user.sesseionStarted.toLocaleString(
              "pt-BR",
              {
                hour: "2-digit",
                hour12: false,
                minute: "2-digit",
              }
            ),
          });
        },
      },
      {
        test: ehelp_widget.settings.routeNames.devices + "/:any/:any",
        resolve: (params: string[]) => {
          ehelp_widget.loading.start();
          const page = ehelp_widget.registerPage(
            new Usecase(params),
            "usecase"
          );
          ehelp_widget.dataLayer({
            userIdentification: ehelp_widget.userCode,
            hourOfSession: ehelp_widget.user.sesseionStarted.toLocaleString(
              "pt-BR",
              {
                hour: "2-digit",
                hour12: false,
                minute: "2-digit",
              }
            ),
          });
        },
      },
      {
        test: "f/:any/:any",
        resolve: (params: string[]) => {
          ehelp_widget.loading.start();
          const page = ehelp_widget.registerPage(
            new Usecase(params),
            "usecase"
          );
          ehelp_widget.dataLayer({
            userIdentification: ehelp_widget.userCode,
            hourOfSession: ehelp_widget.user.sesseionStarted.toLocaleString(
              "pt-BR",
              {
                hour: "2-digit",
                hour12: false,
                minute: "2-digit",
              }
            ),
          });
        },
      },
      {
        test: "trilhas",
        resolve: async () => {
          await ehelp_widget.login();
          ehelp_widget.loading.start();
          const page = ehelp_widget.registerPage(new Trails(), "trails");
          ehelp_widget.dataLayer({
            userIdentification: ehelp_widget.userCode,
            hourOfSession: ehelp_widget.user.sesseionStarted.toLocaleString(
              "pt-BR",
              {
                hour: "2-digit",
                hour12: false,
                minute: "2-digit",
              }
            ),
          });
        },
      },
      {
        test: "trilhas/video-player/:any",
        resolve: async (params: string[]) => {
          await ehelp_widget.login();
          ehelp_widget.loading.start();
          const page = this.registerPage(
            new VideoPlayer(params[0]),
            "videoplayer"
          );
          ehelp_widget.dataLayer({
            userIdentification: ehelp_widget.userCode,
            hourOfSession: ehelp_widget.user.sesseionStarted.toLocaleString(
              "pt-BR",
              {
                hour: "2-digit",
                hour12: false,
                minute: "2-digit",
              }
            ),
          });
        },
      },
    ]);
    await this.loadScript(
      this.build_path + "/assets/js/buscador.qelp.js",
      "buscador-qelp"
    );
    await this.loadScript("https://www.youtube.com/iframe_api", "iframe_api");
    this.loadCSS("https://fonts.googleapis.com/icon?family=Material+Icons");
    this.loadCSS(
      "https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200"
    );
  }
}

const ehelp_widget = new App("#ehelp-widget");

ehelp_widget.config({
  locale: "pt-br",
  loader:
    '<div class="d-flex justify-content-center"> <div class="spinner-border m-5" role="status"> <span class="visually-hidden">Loading...</span> </div> </div>',
  localization: require("./assets/json/localization.json"),
  assets: "https://assets.qelplatam.com/",
  rootFallback: {
    set: "mapultragaz.com.br/universidade-ultragaz",
    mode: "hash",
    environment: Environment.LIVE,
    build_path: "https://cdn.qelplatam.com/ultragaz",
    api: "https://middleware-ultragaz-prod.qelplatam.com/api/",
  },
  root: [
    {
      test: /^localhost:9015\/$/,
      set: "localhost:9015",
      mode: "hash",
      environment: Environment.LIVE,
      build_path: "http://localhost:9015",
    },
    {
      test: /^localhost:3000\/(portal|universidade-ultragaz)$/,
      set: "localhost:3000/universidade-ultragaz",
      mode: "hash",
      build_path: "http://cdn.qelplatam.com/ultragaz-dev",
    },
    {
      test: /^cdn.qelplatam.com\/ultragaz\/index.html$/,
      set: "cdn.qelplatam.com/ultragaz/index.html",
      mode: "hash",
      environment: Environment.LIVE,
      build_path: "https://cdn.qelplatam.com/ultragaz",
    },
    {
      test: /^cdn.qelplatam.com\/ultragaz-accept\/index.html$/,
      set: "cdn.qelplatam.com/ultragaz-accept/index.html",
      build_path: "https://cdn.qelplatam.com/ultragaz-accept",
      environment: Environment.ACCEPT,
      mode: "hash",
    },
    {
      test: /^cdn.qelplatam.com\/ultragaz-dev\/index.html$/,
      set: "cdn.qelplatam.com/ultragaz-dev/index.html",
      mode: "hash",
      build_path: "https://cdn.qelplatam.com/ultragaz-dev",
    },
    {
      test: /^qa-portal-revenda-online-front-end-web.s3-website-sa-east-1.amazonaws.com\/(portal|universidade-ultragaz)$/,
      set: "qa-portal-revenda-online-front-end-web.s3-website-sa-east-1.amazonaws.com/universidade-ultragaz",
      mode: "hash",
      build_path: "https://cdn.qelplatam.com/ultragaz-dev",
    },
    {
      test: /^homolog-portal-revenda-online-front-end-web.s3-website-sa-east-1.amazonaws.com\/(portal|universidade-ultragaz)$/,
      set: "homolog-portal-revenda-online-front-end-web.s3-website-sa-east-1.amazonaws.com/universidade-ultragaz",
      mode: "hash",
      environment: Environment.ACCEPT,
      build_path: "https://cdn.qelplatam.com/ultragaz-accept",
    },
    {
      test: /^mapultragaz.com.br\/(portal|universidade-ultragaz)$/,
      set: "mapultragaz.com.br/universidade-ultragaz",
      mode: "hash",
      environment: Environment.LIVE,
      build_path: "https://cdn.qelplatam.com/ultragaz",
    },
  ],
  api: [
    {
      test: /^cdn.qelplatam.com\/ultragaz\/index.html$/,
      set: "https://middleware-ultragaz-prod.qelplatam.com/api/",
    },
    {
      test: /^cdn.qelplatam.com\/ultragaz-accept\/index.html$/,
      set: "https://middleware-ultragaz-accept.qelplatam.com/api/",
    },
    {
      test: /^localhost:3000\/(portal|universidade-ultragaz)$/,
      set: "https://middleware-ultragaz-desenv.qelplatam.com/api/",
    },
    {
      test: /^cdn.qelplatam.com\/ultragaz-dev\/index.html$/,
      set: "https://middleware-ultragaz-desenv.qelplatam.com/api/",
    },
    {
      test: /qa-portal-revenda-online-front-end-web.s3-website-sa-east-1.amazonaws.com/,
      set: "https://middleware-ultragaz-desenv.qelplatam.com/api/",
    },
    {
      test: /homolog-portal-revenda-online-front-end-web.s3-website-sa-east-1.amazonaws.com/,
      set: "https://middleware-ultragaz-accept.qelplatam.com/api/",
    },
    {
      test: /^localhost:9015\/$/,
      set: "https://middleware-ultragaz-desenv.qelplatam.com/api/",
    },
    {
      test: /^mapultragaz.com.br\/universidade-ultragaz$/,
      set: "https://middleware-ultragaz-prod.qelplatam.com/api/",
    },
  ],
  templates: {
    pages: {
      home: require("./templates/pages/home.handlebars"),
      device: require("./templates/pages/device.handlebars"),
      usecase: require("./templates/pages/usecase.handlebars"),
      trails: require("./templates/pages/trails.handlebars"),
      videoplayer: require("./templates/pages/videoplayer.handlebars"),
    },
    widgets: {
      devices: require("./templates/widgets/devices.handlebars"),
      device_tutorial_list: require("./templates/widgets/device-tutorial-list.handlebars"),
      simple_header: require("./templates/widgets/simple-header.handlebars"),
      trails: require("./templates/widgets/trails.handlebars"),
      faqs: require("./templates/widgets/faqs.handlebars"),
      profile_selector: require("./templates/widgets/profile-selector.handlebars"),
      prs: require("../../templates/widgets/prs.handlebars"),
      back_to_top: require("../../templates/widgets/back-to-top.handlebars"),
      related_topics: require("../../templates/widgets/related-topics.handlebars"),
      trail_list: require("./templates/widgets/trail-list.handlebars"),
      side_menu: require("./templates/widgets/side-menu.handlebars"),
    },
  },
  routeNames: {
    home: "home",
    devices: "temas",
  },
  buscador: {
    api: {
      development: "https://middleware-ultragaz-desenv.qelplatam.com/api/search",
      accept: "https://middleware-ultragaz-accept.qelplatam.com/api/search",
      live: "https://middleware-ultragaz-prod.qelplatam.com/api/search",
    },
    label: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 45 45"><path d="M39.8 41.95 26.65 28.8q-1.5 1.3-3.5 2.025-2 .725-4.25.725-5.4 0-9.15-3.75T6 18.75q0-5.3 3.75-9.05 3.75-3.75 9.1-3.75 5.3 0 9.025 3.75 3.725 3.75 3.725 9.05 0 2.15-.7 4.15-.7 2-2.1 3.75L42 39.75Zm-20.95-13.4q4.05 0 6.9-2.875Q28.6 22.8 28.6 18.75t-2.85-6.925Q22.9 8.95 18.85 8.95q-4.1 0-6.975 2.875T9 18.75q0 4.05 2.875 6.925t6.975 2.875Z"/></svg>',
    placeholder: "Sua dúvida",
    close: '<i class="bi bi-x"></i>',
    back: '<i class="bi bi-x"></i>',
    force_modal: true,
    result_list: {
      head: "Resultados",
      "no-results": "Não foram encontrados resultados para esta busca",
    },
    list: {
      history: {
        label: "Buscas anteriores",
      },
      topics: {
        label: "Passo a passo",
        notfound: "Não foram encontrados resultados para esta busca",
      },
    },
    max: {
      topics: 10,
      history: 3,
      width: 450,
    },
  },
  extra: {
    api_ultragaz: {
      development: {
        auth: "https://api-sandbox.ultragaz.com.br/oauth/access-token",
        api: "https://api-sandbox.ultragaz.com.br/residential/ultratop/v1/",
        client_id: "1d1959d5-c9bc-402b-9f6a-41defd011e93",
        access: "e5fb1742-1e3a-4891-8645-4d37b7d4a6e5",
      },
      accept: {
        auth: "https://api-sandbox.ultragaz.com.br/oauth/access-token",
        api: "https://api-sandbox.ultragaz.com.br/residential/ultratop/v1/",
        client_id: "1d1959d5-c9bc-402b-9f6a-41defd011e93",
        access: "e5fb1742-1e3a-4891-8645-4d37b7d4a6e5",
      },
      live: {
        auth: "https://api.ultragaz.com.br/oauth/access-token",
        api: "https://api.ultragaz.com.br/residential/ultratop/v1/",
        client_id: "7407f0b2-f3de-43e9-a84c-56c348a64304",
        access: "403b5c48-37eb-4450-8e7b-92be5d910633",
      },
    },
  },
});