import { sha256 } from "js-sha256";

export class User {
  data: UserData;
  session: Date;
  sesseionStarted: Date;
  sessionDuration = 30; //minutes
  constructor() {
    try {
      const user_string = window.localStorage.getItem(
        "eHelpWidget_dynamic_user"
      );
      if (!user_string) {
        this.data = {
          id: sha256(window.navigator.userAgent + new Date()),
          ratings: {},
          profile: 0
        };
        window.localStorage.setItem(
          "eHelpWidget_dynamic_user",
          JSON.stringify(this.data)
        );
      } else {
        this.data = JSON.parse(user_string);
      }
    } catch (error) {
      console.log(error);
    }
    this.initSession();
  }
  initSession() {
    const session_str = window.localStorage.getItem("eHelpWidgetSession");
    const session_started_str = window.localStorage.getItem("eHelpWidgetSessionStarted");
    let old: Date;
    let now = new Date();
    let session_started = new Date();

    if (session_str || session_started_str) {
      session_started = new Date(Number(session_started_str));
      old = new Date(Number(session_str));
      // old = new Date('Sun Oct 09 2022 16:39:35 GMT-0300 (Brasilia Standard Time)');
    } else {
      old = new Date();
      session_started = new Date();
      window.localStorage.setItem(
        "eHelpWidgetSessionStarted",
        session_started.getTime().toString()
      );
    }

    const datediff = Math.abs(now.getTime() - old.getTime());

    if (this.msToMinutes(datediff) > this.sessionDuration) {
      window.localStorage.setItem(
        "eHelpWidgetSessionStarted",
        now.getTime().toString()
      );
      this.sesseionStarted = now;
    } else {
      this.sesseionStarted = new Date(session_started.toString());
    }

    // console.log("started: " + session_started);
    // console.log("old: " + old);
    // console.log("now: " + now);
    // console.log("datediff: " + datediff);
    // console.log("minutesdiff: " + this.msToMinutes(datediff));
    
    window.localStorage.setItem(
      "eHelpWidgetSession",
      now.getTime().toString()
    );  
    this.session = now;
  }
  msToMinutes(ms:number): number {
    return ms / 1000 / 60;
  }

  update() {
    window.localStorage.setItem(
      "eHelpWidget_dynamic_user",
      JSON.stringify(this.data)
    );
  }
  vote(
    data: RatingData = { hashkey: null, rating: null },
    callback: Function = null
  ) {
    this.data.ratings[data.hashkey] = data.rating;
    if (callback) callback();
    this.update();
  }
}

export interface UserData {
  id: string;
  ratings: RatingData;
  profile?: number;
}
export interface RatingData {
  [key: string]: string | number;
  hashkey?: string;
  rating?: number;
}
